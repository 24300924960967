import * as Yup from 'yup';

export const schemas = {
  login: Yup.object().shape({
    email: Yup.string().required('Email is Required!'),
    password: Yup.string().required('Password is Required!'),
  }),
  project: Yup.object().shape({
    name: Yup.string().required('Name is Required!'),
    participants: Yup.array()
      .of(Yup.number().integer('Please enter a valid Participants'))
      .min(1, 'Participants are Required!'),
  }),
  opportunity: Yup.object().shape({
    name: Yup.string().required('Opportunity is Required!'),
  }),
};
