import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { actions } from 'src/redux/reducers/projectSlice';

const projectActions = {
  getOwnedProjects: createAsyncThunk('project/getOwnedProjects', api.getOwnedProjects),
  getJoinedProjects: createAsyncThunk('project/getJoinedProjects', api.getJoinedProjects),
  getProject: createAsyncThunk('project/getProject', api.getProject),
  createProject: createAsyncThunk('project/createProject', api.createProject),
  updateProject: createAsyncThunk('project/updateProject', api.updateProject),
  getOpportunities: createAsyncThunk('project/getOpportunities', api.getOpportunities),
  getSelectedOpportunities: createAsyncThunk(
    'project/getSelectedOpportunities',
    api.getSelectedOpportunities,
  ),
  getCriteria: createAsyncThunk('project/getCriteria', api.getCriteria),
  getProjectEntities: createAsyncThunk('project/getProjectEntities', api.getProjectEntities),
  getPeopleOrgOutputs: createAsyncThunk('project/getPeopleOrgOutputs', api.getPeopleOrgOutputs),
  ...actions,
};

export default projectActions;
