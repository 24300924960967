import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'src/api';

const INITIAL_STATE = {
  beneficiaries: [],
  learnings: [],
  loading: false,
  error: null,
};

export const fetchBeneficiaries = createAsyncThunk(
  'beneficiaries/fetchBeneficiaries',
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await api.getProjectBeneficiaries(projectId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProjectLearning = createAsyncThunk(
  'beneficiaries/fetchProjectLearning',
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await api.getProjectLearnings(projectId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const beneficiariesSlice = createSlice({
  name: 'beneficiaries',
  initialState: INITIAL_STATE,
  reducers: {
    resetBeneficiarySlice: () => INITIAL_STATE,
    updateBeneficiariesOnCreate(state, action) {
      const newBeneficiary = action.payload;
      const updatedBeneficiaries = { ...state.beneficiaries };
      const { bf_type, created_by } = newBeneficiary;
      updatedBeneficiaries[bf_type] = updatedBeneficiaries[bf_type] || {};
      updatedBeneficiaries[bf_type][created_by] = updatedBeneficiaries[bf_type][created_by] || [];
      updatedBeneficiaries[bf_type][created_by].push(newBeneficiary);
      state.beneficiaries = updatedBeneficiaries;
    },
    updateLearnings(state, action) {
      const newLearning = action.payload;
      const updatedLearnings = { ...state.learnings };
      const { created_by } = newLearning;
      updatedLearnings[created_by] = updatedLearnings[created_by] || [];
      updatedLearnings[created_by].push(newLearning);
      state.learnings = updatedLearnings;
    },
    deleteProjectLearning(state, action) {
      console.log('hello');
      const { id, created_by } = action.payload;
      const updatedLearnings = { ...state.learnings };
      if (updatedLearnings?.[created_by] && Array.isArray(updatedLearnings[created_by])) {
        updatedLearnings[created_by] = updatedLearnings[created_by].filter(
          (learning) => learning.id !== id,
        );
        state.learnings = updatedLearnings;
      }
    },
    deleteBeneficiaries(state, action) {
      const { id, created_by, bf_type } = action.payload;
      const updatedBeneficiaries = { ...state.beneficiaries };

      if (updatedBeneficiaries[bf_type] && updatedBeneficiaries[bf_type][created_by]) {
        updatedBeneficiaries[bf_type][created_by] = updatedBeneficiaries[bf_type][
          created_by
        ].filter((beneficiary) => beneficiary.id !== id);

        // If there are no beneficiaries left for a particular type and creator, remove the empty array
        if (updatedBeneficiaries[bf_type][created_by].length === 0) {
          delete updatedBeneficiaries[bf_type][created_by];
        }

        // If there are no beneficiaries left for a particular type, remove the empty object
        if (Object.keys(updatedBeneficiaries[bf_type]).length === 0) {
          delete updatedBeneficiaries[bf_type];
        }

        // Update the state
        state.beneficiaries = updatedBeneficiaries;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBeneficiaries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchBeneficiaries.fulfilled, (state, action) => {
      state.beneficiaries = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchBeneficiaries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectLearning.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProjectLearning.fulfilled, (state, action) => {
      state.learnings = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchProjectLearning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {
  resetBeneficiarySlice,
  updateBeneficiariesOnCreate,
  deleteBeneficiaries,
  updateLearnings,
  deleteProjectLearning,
} = beneficiariesSlice.actions;

export default beneficiariesSlice.reducer;
