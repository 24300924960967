import React, { useEffect, useState } from 'react';
import { AutoComplete, Divider, Input, message, Modal, Select, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { send_events } from 'src/sockets';
import { errors, globals, hooks, labels, routes, utils } from 'src/common';
import { NavigationFooter, TagRender } from 'src/components';
import { projectActions } from 'src/redux/actions';
import Button from 'src/components/button';
import Typography from 'src/components/typography/typography';
import 'src/pages/problemCanvas/index.css';
import { debounce, keys } from 'lodash';
import { fetchProjectTechnologies, fetchSolutions } from 'src/redux/reducers/problemCanvasSlice';
import { useSendJsonMessage } from 'src/sockets/useSendJsonMessage';
import { api } from 'src/api';
import { PROBLEM_CANVAS_MESSAGES } from 'src/common/constants';

const ProblemCanvas = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { sendJsonMessage } = useSendJsonMessage();
  const project = useSelector((state) => state.project.selected);
  const technologies = useSelector((state) => state.problemCanvas.technologies);
  const canvasData = useSelector((state) => state.problemCanvas.solutions);

  const [isAddTechModalVisible, setIsAddTechModalVisible] = useState(false);
  const [isAddSubProblemModalVisible, setIsAddSubProblemModalVisible] = useState(false);
  const [newTechName, setNewTechName] = useState('');
  const [newSubProblemName, setNewSubProblemName] = useState('');
  const [editTechModalVisible, setEditTechModalVisible] = useState(false);
  const [editSubProblemModalVisible, setEditSubProblemModalVisible] = useState(false);
  const [editingTechId, setEditingTechId] = useState(null);
  const [editingSubProblemId, setEditingSubProblemId] = useState(null);
  const [editingTechName, setEditingTechName] = useState('');
  const [editingSubProblemName, setEditingSubProblemName] = useState('');
  const [suggestedTechnologies, setSuggestedTechnologies] = useState([]);

  const editTechnology = () => {
    if (editingTechId && editingTechName.trim() !== '') {
      sendJsonMessage({
        type: send_events.UPDATE_PROJECT_TECHNOLOGY,
        data: {
          id: editingTechId,
          technology: {
            name: editingTechName,
          },
        },
      });
      setEditTechModalVisible(false);
      setEditingTechId(null);
      setEditingTechName('');
    }
  };

  const getSuggestedTechnologies = debounce(async () => {
    const response = await api.getSuggestedTechnologies();
    setSuggestedTechnologies(
      response.results.map((option) => ({
        key: option['id'],
        value: option['name'],
        label: option['name'],
      })),
    );
  }, 300);

  const editSubProblem = () => {
    if (editingSubProblemId && editingSubProblemName.trim() !== '') {
      sendJsonMessage({
        type: send_events.UPDATE_PROJECT_SUB_PROBLEM,
        data: {
          id: editingSubProblemId,
          name: editingSubProblemName,
        },
      });
      setEditSubProblemModalVisible(false);
      setEditingSubProblemId(null);
      setEditingSubProblemName('');
    }
  };

  const addTechnologyColumn = () => {
    if(newTechName) {
      sendJsonMessage({
        type: send_events.CREATE_PROJECT_TECHNOLOGY,
        data: {
          technology: {
            name: newTechName,
          },
        },
      });
      setNewTechName('');
      setIsAddTechModalVisible(false);
    }
    else {
      message.error(PROBLEM_CANVAS_MESSAGES.TECHNOLOGY_NAME_NOT_ENTERED)
    }
  };

  const addSubProblemRow = () => {
    if (newSubProblemName) {
      sendJsonMessage({
        type: send_events.CREATE_PROJECT_SUB_PROBLEM,
        data: {
          name: newSubProblemName,
        },
      });
      setNewSubProblemName('');
      setIsAddSubProblemModalVisible(false);
    }
    else{
      message.error(PROBLEM_CANVAS_MESSAGES.SUB_PROBLEM_NAME_NOT_ENTERED)
    }
  };

  const deleteTechnologyColumn = (technologyId) => {
    Modal.confirm({
      title: PROBLEM_CANVAS_MESSAGES.DELETE_CONFIRMATION,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        sendJsonMessage({
          type: send_events.DELETE_PROJECT_TECHNOLOGY,
          data: {
            id: technologyId,
          },
        });
        message.success(PROBLEM_CANVAS_MESSAGES.TECHNOLOGY_DELETED);
      },
    });
  };

  const deleteSubProblemRow = (subProblemKey) => {
    Modal.confirm({
      title: PROBLEM_CANVAS_MESSAGES.DELETE_SUB_PROBLEM_CONFIRMATION,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        sendJsonMessage({
          type: send_events.DELETE_PROJECT_SUB_PROBLEM,
          data: {
            id: subProblemKey,
          },
        });
        message.success(PROBLEM_CANVAS_MESSAGES.SUB_PROBLEM_DELETED);
      },
    });
  };

  const columns = [
    {
      title: (
        <div className='first-header-column'>
          <Typography black fontSize='14px'>
            Tech vs Subproblem
          </Typography>
        </div>
      ),
      dataIndex: 'subProblem',
      key: 'subProblem',
      className: 'column-max-width',
      render: (text) => {
        return {
          props: {
            style: {
              background: '#C2DBFD',
            },
          },
          children: <div>{text}</div>,
        };
      },
      fixed: 'left',
    },
    ...(technologies || []).map((tech) => ({
      title: (
        <div className='header-column'>
          <Typography black fontSize='14px'>
            {tech.technology_name}
          </Typography>
          <div>
            <EditOutlined
              onClick={() => {
                setEditingTechId(tech.id);
                setEditingTechName(tech.technology_name);
                setEditTechModalVisible(true);
              }}
              className='edit-icon'
              style={{ marginRight: '8px' }}
            />
            <DeleteOutlined
              onClick={() => {
                deleteTechnologyColumn(tech.id);
              }}
              className='edit-icon'
            />
          </div>
        </div>
      ),
      dataIndex: tech.id,
      key: tech.id,
      render: (text) => {
        return {
          props: {
            style: {
              background: '#F0D0DD',
            },
          },
          children: <div>{text}</div>,
        };
      },
    })),
  ];

  const data = keys(canvasData).map((subProblemKey, index) => {
    const subProblem = canvasData[subProblemKey];
    const { name, ...subProblemTechnologies } = subProblem;
    const restData = {};
    technologies.forEach(({ id: technologyId }) => {
      restData[technologyId] = (
        <Select
          mode='tags'
          id={`${index}-${technologyId}-Problem-Canvas`}
          className='problemCanvas__select'
          tagRender={TagRender}
          value={(subProblemTechnologies[technologyId] || []).map((tag) => ({
            label: tag.name,
            value: tag.id,
          }))}
          style={{ width: '100%', height: 'auto' }}
          onKeyDown={(e) => {
            utils.inputLimiter(e);
          }}
          onSelect={(value) => {
            if (value.length <= globals.MAX_TAG_LENGTH) {
              sendJsonMessage({
                type: send_events.CREATE_PROJECT_SOLUTION,
                data: {
                  name: value,
                  technology: technologyId,
                  sub_problem: subProblemKey,
                },
              });
            }
          }}
          onDeselect={(id) => {
            sendJsonMessage({
              type: send_events.DELETE_PROJECT_SOLUTION,
              data: {
                id,
              },
            });
          }}
          onSearch={() => {
          }}
          options={[]}
        />
      );
    });
    return {
      key: index,
      subProblem: (
        <div className='firstRowItem'>
          <Typography black fontSize='14px'>
            {name}
          </Typography>
          <div>
            <EditOutlined
              onClick={() => {
                setEditingSubProblemId(subProblemKey);
                setEditingSubProblemName(name);
                setEditSubProblemModalVisible(true);
              }}
              className='edit-icon'
              style={{ marginRight: '4px' }}
            />
            <DeleteOutlined
              onClick={() => {
                deleteSubProblemRow(subProblemKey);
              }}
              className='edit-icon'
            />
          </div>
        </div>
      ),
      ...restData,
    };
  });

  useEffect(() => {
    dispatch(fetchProjectTechnologies(id));
    dispatch(fetchSolutions(id));
    dispatch(projectActions.getProject(id));
  }, [id]);

  hooks.useScrollToTop();
  hooks.usePageTitle(labels.PROBLEM_CANVAS);

  return (
    <>
      <div className='problemCanvas__containerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider />
        <Typography tag='p' fontSize='30px' fontWeight={500} blue>
          PROBLEM CANVAS
        </Typography>
        <div style={{ maxWidth: 873 }}>
          <Typography fontSize='14px' fontStyle='italic' lineHeight='21px'>
            Please fill in the Problem Canvas which is a guide for associating problems with
            available technologies in order to discover the known or unknown relationship between
            them (after leveraging insights, interrelationships, and the findings obtained from the
            resource map).
          </Typography>
        </div>
        <Typography fontSize='14px' fontStyle='italic' lineHeight='21px'>
          <ol>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Make a list of all the{' '}
                <b>
                  <span className='blueColor'>subproblems</span>
                </b>{' '}
                associated with your opportunity/topic.
              </Typography>
            </li>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Make a list of all{' '}
                <b>
                  <span className='greenColor'>technologies</span>
                </b>{' '}
                that could be leveraged in creating solutions (not limited by the opportunity).
              </Typography>
            </li>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Come up with ways that the{' '}
                <b>
                  <span className='greenColor'>technology</span>
                </b>{' '}
                can provide solutions to the{' '}
                <b>
                  <span className='blueColor'>subproblems</span>
                </b>{' '}
                by writing out{' '}
                <b>
                  <span className='redColor'>Potential Solution</span>
                </b>
                .
              </Typography>
            </li>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                As a team, upvote (or downvote) each{' '}
                <b>
                  <span className='redColor'>Potential Solution</span>
                </b>{' '}
                to find out which options are most compelling for the team.
              </Typography>
            </li>
          </ol>
        </Typography>

        <div>
          <Modal
            title='Add Technology'
            visible={isAddTechModalVisible}
            onOk={addTechnologyColumn}
            onCancel={() => setIsAddTechModalVisible(false)}
            okText='Add'
          >
            <AutoComplete
              showSearch={true}
              placeholder='Select or create a technology'
              onKeyDown={(e) => {
                utils.inputLimiter(e);
              }}
              options={suggestedTechnologies}
              onChange={(e) => e.length <= globals.MAX_TAG_LENGTH ? setNewTechName(e) : message.error(errors.CHARACTER_LIMIT_REACHED)}
              value={newTechName}
              onSearch={getSuggestedTechnologies}
              filterOption={false}
            />
          </Modal>
          <Modal
            title='Add subproblem'
            visible={isAddSubProblemModalVisible}
            onOk={addSubProblemRow}
            onCancel={() => setIsAddSubProblemModalVisible(false)}
            okText='Add'
          >
            <Input
              value={newSubProblemName}
              placeholder='Please enter a subproblem'
              onChange={(e) => setNewSubProblemName(e.target.value)}
              maxLength={globals.MAX_TAG_LENGTH}
              onKeyDown={(e) => {
                utils.inputLimiter(e);
              }}
            />
          </Modal>
          <Modal
            title='Edit Technology'
            visible={editTechModalVisible}
            okText={labels.UPDATE}
            onOk={editTechnology}
            onCancel={() => {
              setEditTechModalVisible(false);
              setEditingTechId(null);
              setEditingTechName('');
            }}
          >
            <AutoComplete
              showSearch={true}
              placeholder='Select or create a technology'
              onKeyDown={(e) => {
                utils.inputLimiter(e);
              }}
              options={suggestedTechnologies}
              onChange={(e) => e.length <= globals.MAX_TAG_LENGTH ? setEditingTechName(e) : message.error(errors.CHARACTER_LIMIT_REACHED)}
              value={editingTechName}
              onSearch={getSuggestedTechnologies}
              filterOption={false}
            />
          </Modal>
          <Modal
            title='Edit subproblem'
            visible={editSubProblemModalVisible}
            okText={labels.UPDATE}
            onOk={editSubProblem}
            onCancel={() => {
              setEditSubProblemModalVisible(false);
              setEditingSubProblemId(null);
              setEditingSubProblemName('');
            }}
          >
            <Input
              value={editingSubProblemName}
              onChange={(e) => setEditingSubProblemName(e.target.value)}
              maxLength={globals.MAX_TAG_LENGTH}
              onKeyDown={(e) => {
                utils.inputLimiter(e);
              }}
            />
          </Modal>
          <Table
            pagination={false}
            columns={columns.map((column) => ({
              ...column,
              onCell: (record, rowIndex) => {
                return utils.focusCellElement(`${rowIndex}-${column?.dataIndex}-Problem-Canvas`)
              },
            }))}
            dataSource={data}
            className='problem-canvas'
            scroll={{ x: true }}
            bordered={true}
          />
          <div className='problemCanvas__actionButtonContainer'>
            <Button className='btn' onClick={() => setIsAddSubProblemModalVisible(true)}>
              Add subproblem
            </Button>
            <Button className='btn' onClick={() => setIsAddTechModalVisible(true)}>
              Add Technology
            </Button>
          </div>
        </div>
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${id}/breakthroughs-risks`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/solution-canvas`)}
      />
    </>
  );
};

export default ProblemCanvas;
