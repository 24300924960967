import React, { useEffect } from 'react';
import 'src/pages/login/_login.scss';
import { globals, hooks, labels, schemas } from 'src/common';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'src/redux/actions';
import { clauses } from 'src/common/constants';
import { ToastContainer } from 'react-toastify';

function Login({ setTokens }) {
  const dispatch = useDispatch();
  const tokens = useSelector((state) => state.auth.tokens);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      dispatch(authActions.login(values));
    },
    validationSchema: schemas.login,
  });

  useEffect(() => {
    if (tokens) {
      setTokens(tokens);
    }
  }, [tokens]);

  hooks.usePageTitle(labels.LOGIN);
  return (
    <div className='loginWrapper'>
      <div className='login'>
        <form className='loginForm' onSubmit={formik.handleSubmit}>
          <h1 className='title'>{labels.APP}</h1>
          <div className='inputRow'>
            <label htmlFor='email'>{labels.EMAIL}</label>
            <input
              type='email'
              id='email'
              name='email'
              placeholder={clauses.ENTER_EMAIL}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && <span className='error'>{formik.errors.email}</span>}
          </div>
          <div className='inputRow'>
            <label htmlFor='password'>{labels.PASSWORD}</label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder={clauses.ENTER_PASSWORD}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && <span className='error'>{formik.errors.password}</span>}
          </div>
          <input className='submitBtn' type='submit' value={labels.LOGIN} />
        </form>
      </div>
      <ToastContainer autoClose={globals.TOAST_TIMER} />
    </div>
  );
}

export default Login;
