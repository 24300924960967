import React, { useEffect, useState } from 'react';
import Opportunity from './pages/opportunity/Opportunity';
import SelectedOpportunity from './pages/selectedOpportunity';
import UsersAndBeneficiaries from './pages/usersAndBeneficiaries';
import BreakthroughsAndRisks from './pages/breakthroughsAndRisks';
import PeopleAndOrganizations from './pages/peopleAndOrganizations/PeopleAndOrganizations';
import SolutionCanvas from './pages/solutionCanvas/SolutionCanvas';
import ProblemCanvas from './pages/problemCanvas';
import { handleEvent, useWebSocketContext } from './sockets';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes as Switch, Navigate } from 'react-router-dom';

import { Login, Project } from 'src/pages';
import Home from 'src/pages/home/Home';
import FindingsPlot from 'src/pages/findingsPlot';
import Summary from 'src/pages/summary/summary';

import { globals, hooks, routes } from 'src/common';

import 'react-toastify/dist/ReactToastify.css';
import 'src/scss/App.scss';
import NavigationBar from './components/navBar/NavBar';

function App() {
  const { accessToken, setTokens } = hooks.useToken();
  const dispatch = useDispatch();

  const { lastJsonMessage } = useWebSocketContext();
  const [canNavigate, setCanNavigate] = useState(true);
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    lastJsonMessage && handleEvent(dispatch, lastJsonMessage);
  }, [lastJsonMessage]);

  return accessToken ? (
    <div className='app'>
      <Router>
        <NavigationBar canNavigate={canNavigate} setNextPage={setNextPage}>
          <Switch>
            <Route path={routes.HOME} element={<Home />} />
            {[routes.PROJECT, routes.PROJECT_DYN].map((path, index) => (
              <Route path={path} element={<Project setCanNavigate={setCanNavigate} nextPage={nextPage}/>} key={index} />
            ))}
            <Route path={routes.SELECTED_OPPORTUNITY} element={<SelectedOpportunity />} />
            <Route path={routes.PROJECT_OPPORTUNITIES_DYN} element={<Opportunity />} />
            <Route
              path={routes.RESOURCE_MAP_USERS_BENEFICIARIES}
              element={<UsersAndBeneficiaries />}
            />
            <Route
              path={routes.RESOURCE_MAP_BREAKTHROUGHS_RISKS}
              element={<BreakthroughsAndRisks />}
            />
            <Route path={routes.PEOPLE_AND_ORGANIZATIONS} element={<PeopleAndOrganizations />} />
            <Route path={routes.SOLUTION_CANVAS} element={<SolutionCanvas />} />
            <Route path={routes.PROBLEM_CANVAS} element={<ProblemCanvas />} />
            <Route path={routes.FINDINGS_PLOT} element={<FindingsPlot />} />
            <Route path={routes.SUMMARY} element={<Summary />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Switch>
        </NavigationBar>
      </Router>

      <ToastContainer autoClose={globals.TOAST_TIMER} />
    </div>
  ) : (
    <Login setTokens={setTokens} />
  );
}

export default App;
