import React from 'react';
import {
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import propTypes from 'prop-types';
import { values } from 'lodash';

const COLORS = [
  '#0088FE',
  'red',
  '#FFBB28',
  '#00C49F',
  '#FF8042',
  'aqua',
  'gray',
  'blue',
  'brown',
  'pink',
];

const OFFSET = 0.1;

const offsetData = (data) => {
  const adjustedData = values(data);

  for (let i = 0; i < adjustedData.length; i++) {
    for (let j = i + 1; j < adjustedData.length; j++) {
      if (
        Math.abs(adjustedData[i].cost - adjustedData[j].cost) < OFFSET &&
        Math.abs(adjustedData[i].time - adjustedData[j].time) < OFFSET
      ) {
        adjustedData[j] = {
          ...adjustedData[j],
          cost: adjustedData[j].cost - OFFSET,
        };
      }
    }
  }

  return adjustedData;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className='customTooltip'>
        <p className='tooltipHead'>{data.solution_name}</p>
        <p>Cost: {Math.ceil(data.cost)}</p>
        <p>Time: {data.time}</p>
        <p>Impact: {data.impact}</p>
      </div>
    );
  }
  return null;
};

const BubbleChart = ({ data }) => {
  const domain = [0, 10];
  let cells = [],
    legends = [];

  const scatterData = offsetData(data);

  scatterData.forEach((value, index) => {
    const color = COLORS[index % COLORS.length];
    cells.push(<Cell key={`bubble-${index}`} fill={color} />);
    legends.push({
      id: value.solution_name,
      value: `${value.solution_name}`,
      color: color,
    });
  });

  return (
    <div className='bubbleChart'>
      <ResponsiveContainer width='100%' height='100%'>
        <ScatterChart margin={{ top: 60, right: 30, bottom: 40, left: 30 }}>
          <CartesianGrid />
          <XAxis
            type='number'
            dataKey='cost'
            label={{
              value: 'Cost',
              position: 'insideBottom',
              dy: 10,
              style: { fontWeight: 'bold' },
            }}
            domain={domain}
            tickCount={10}
          />
          <YAxis
            type='number'
            dataKey='time'
            label={{
              value: 'Time',
              angle: -90,
              dx: 15,
              position: 'insideLeft',
              style: { fontWeight: 'bold' },
            }}
            domain={domain}
            tickCount={10}
          />
          <ZAxis type='number' dataKey='impact' domain={domain} range={[0, 10000]} />
          <Tooltip allowEscapeViewBox={{ x: true, y: true }} content={CustomTooltip} />
          <Scatter animationBegin={0} data={scatterData} fillOpacity={0.7}>
            {cells}
          </Scatter>
          <Legend
            layout='vertical'
            verticalAlign='start'
            align='right'
            wrapperStyle={{ paddingLeft: '60px' }}
            formatter={(value, entry, index) => <span className='customLegend'>{value}</span>}
            payload={legends}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

BubbleChart.propTypes = {
  data: propTypes.arrayOf(propTypes.shape({})),
};

export default BubbleChart;
