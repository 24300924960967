export const UserTypes = {
  POTENTIAL_USER: 0,
  INFLUENCER: 1,
  SPONSOR: 2,
};

export const QUESTIONS = {
  POTENTIAL_USER: 'Who are potential users, who benefit from this opportunity?',
  INFLUENCER: 'Who are the main influencers related to opportunity?',
  SPONSOR: 'Who might pay for the solution? Who might be sponsors?',
};

export const materialColumns = [
  {
    title: 'Participants',
    dataIndex: 'participant',
    key: 'participant',
    className: 'first-column',
    width: '10%',
  },
  {
    title: 'Data',
    dataIndex: 'responses',
    key: 'responses',
    width: '33.3%',
  },
];
