export const InfluenceTypes = {
  BREAKTHROUGH: 0,
  BENEFIT: 1,
  IMPACT: 2,
  FRICTION: 3,
  FINANCIAL_RISK: 4,
  LIMITING_FACTOR: 5,
};

export const QUESTIONS = {
  BREAKTHROUGH:
    'Please think about breakthroughs the opportunity can emerge and investigate current and future trends.',
  BENEFIT: 'What are the main payoffs, results, benefits from the opportunity?',
  IMPACT: 'What is the IMPACT?',
  FRICTION: 'How different frictions – regulation, biases, etc. influence the opportunity?',
  FINANCIAL_RISK: 'What are the possible financial risks?',
  LIMITING_FACTOR:
    'What are execution risks and limiting factors (e.g. time, distance, lack of existing infrastructure)?',
};

export const materialColumns = [
  {
    title: 'Participants',
    dataIndex: 'participant',
    key: 'participant',
    className: 'first-column',
    width: '10%',
  },
  {
    title: 'Data',
    dataIndex: 'responses',
    key: 'responses',
    width: '33.3%',
  },
];

export const columns = [
  {
    title: 'Challenge',
    dataIndex: 'challenge',
    key: 'challenge',
  },
  {
    title: 'Opportunity',
    dataIndex: 'opportunity',
    key: 'opportunity',
  },
];
