export const labels = {
  APP: 'Spot Probe',
  LOGIN: 'Login',
  HOME: 'Home',
  PROJECT: 'Project',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PROCEED: 'Proceed',
  CREATE_NEW: 'Create New',
  WELCOME: 'Welcome',
  NEXT: 'Next',
  BACK: 'Back',
  OPPORTUNITIES: 'Opportunities',
  ADD_OPPORTUNITY: 'Add Opportunity',
  SELECTED_BIG_OPPORTUNITY: 'Select Big Opportunity',
  PEOPLE_AND_ORG: 'People and Organizations',
  USER_AND_BENEFICIARY: 'Users and Beneficiaries',
  BREAKTHROUGH_AND_RISK: 'Breakthroughs and Risks',
  PROBLEM_CANVAS: 'Problem Canvas',
  SOLUTION_CANVAS: 'Solution Canvas',
  FINDINGS_PLOT: 'Findings Plot',
  SUMMARY: 'Summary of Findings',
  UPDATE: 'Save'
};

export const clauses = {
  ENTER_EMAIL: 'your email...',
  ENTER_PASSWORD: 'your password...',
  ENTER_PROJECT_NAME: 'Please enter the name of Project',
  PROJECT_NAME: 'project name...',
  ENTER_PARTICIPANTS: 'List the names of Participants in this Brainswarming session',
  PARTICIPANT: 'participant...',
  OPPORTUNITY: 'As a team, begin to brainstorm different opportunities here:',
  PROJECT_NAME_REQUIRED: 'Please input the project name!',
};

export const globals = {
  TOAST_TIMER: 3000,
  MAX_TAG_LENGTH: 128
};

export const USER_AND_BENEFICIARY_OUTPUT_MESSAGES = {
  DELETE_CONFIRMATION: 'Are you sure you want to delete this output?',
  OUTPUT_DELETED: 'Output deleted successfully.',
};

export const BREAKTHROUGH_AND_RISK_OUTPUT_LABEL = {
  ENTER_CHALLENGE_LABEL: 'Please enter a challenge',
  ENTER_CHALLENGE_PLACEHOLDER: 'Enter challenge...',
  ENTER_OPPORTUNITY_LABEL: 'Please enter a opportunity',
  ENTER_OPPORTUNITY_PLACEHOLDER: 'Enter opportunity...',
};

export const PROBLEM_CANVAS_MESSAGES = {
  DELETE_CONFIRMATION: 'Are you sure you want to delete this technology?',
  TECHNOLOGY_DELETED: 'Technology deleted successfully.',
  DELETE_SUB_PROBLEM_CONFIRMATION: 'Are you sure you want to delete this subproblem?',
  SUB_PROBLEM_DELETED: 'Subproblem deleted successfully.',
  SUB_PROBLEM_NAME_NOT_ENTERED: 'Please enter a sub problem name',
  TECHNOLOGY_NAME_NOT_ENTERED: 'Please enter a technology name',
};

export const PAGE_NAMES = {
  USER_AND_BENEFICIARY: 'user_and_beneficiary',
  PEOPLE_AND_ORG: 'people_and_org',
  BREAKTHROUGH_AND_RISKS: 'breakthrough_and_risks',
};
