import React from 'react';
import NumberInput from 'src/components/numberInput/NumberInput';
import TextInput from 'src/components/textInput/TextInput';
import TagInput from 'src/components/TagInput/tagInput';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const sumOfConstraints = (solution) => {
  const desiredKeys = ['skill', 'competition', 'impact', 'time', 'cost'];

  const filteredValues = Object.values(solution).filter((value, index) =>
    desiredKeys.includes(Object.keys(solution)[index]),
  );

  return filteredValues.reduce((sum, value) => {
    const parsedValue = parseInt(value);
    return sum + (isNaN(parsedValue) ? 0 : parsedValue);
  }, 0);
};

export const createPotentialSolutionRows = (
  potentialSolutions,
  factors,
  handleScoreUpdate,
  handlePsFactorUpdate,
  onTagAdd,
  onTagRemove,
  onTagSearch,
) => {
  const rows = [];
  potentialSolutions.forEach((solution, index) => {
    const factorsData = {};
    const psFactors = solution?.ps_factors;
    factors.forEach(
      (factor) =>
        (factorsData[factor.id] = (
          <TextInput id={`${index}-${factor?.id}-Solution-Canvas`}
                     onUpdate={(value) => handlePsFactorUpdate(solution.id, factor.id, value)}
                     placeholder={'Add factor...'} customKey={factor.factor_name + solution.id} />
        )),
    );

    Object.keys(psFactors).forEach(
      (key) =>
        factorsData[key] && (factorsData[key] = (
          React.cloneElement(factorsData[key], { initialValue: psFactors[key].detail })
        )),
    );

    rows.push({
      key: solution.id,
      Solution: solution.solution_name,
      Cost: (
        <NumberInput
          id={`${index}-Cost-Solution-Canvas`}
          initialValue={solution?.cost}
          onUpdate={(cost) => handleScoreUpdate('cost', solution.id, cost)}
          customKey={'cost' + solution.id}
        />
      ),

      Time: (
        <NumberInput
          id={`${index}-Time-Solution-Canvas`}
          initialValue={solution?.time}
          onUpdate={(time) => handleScoreUpdate('time', solution.id, time)}
          customKey={'time' + solution.id}
        />
      ),

      Impact: (
        <NumberInput
          id={`${index}-Impact-Solution-Canvas`}
          initialValue={solution?.impact}
          onUpdate={(impact) => handleScoreUpdate('impact', solution.id, impact)}
          customKey={'impact' + solution.id}
        />
      ),

      Competition: (
        <NumberInput
          id={`${index}-Competition-Solution-Canvas`}
          initialValue={solution?.competition}
          onUpdate={(competition) => handleScoreUpdate('competition', solution.id, competition)}
          customKey={'competition' + solution.id}
        />
      ),

      Skills: (
        <NumberInput
          id={`${index}-Skills-Solution-Canvas`}
          initialValue={solution?.skill}
          onUpdate={(skill) => handleScoreUpdate('skill', solution.id, skill)}
          customKey={'skill' + solution.id}
        />
      ),

      People: (
        <TagInput
          id={`${index}-People-Solution-Canvas`}
          tags={solution?.ps_entities}
          accessKey={'entity_name'}
          isViewOnly={false}
          onAdd={(event) => onTagAdd('entity', solution.id, event)}
          onRemove={(tagId) => onTagRemove('entity', tagId)}
          onSearch={(event) => onTagSearch('entity', solution.id, event)}
          customKey={'entities' + solution.id}
        />
      ),

      Technologies: (
        <TagInput
          id={`${index}-Technologies-Solution-Canvas`}
          tags={solution?.ps_technologies}
          accessKey={'technology_name'}
          isViewOnly={false}
          onAdd={(event) => onTagAdd('technology', solution.id, event)}
          onRemove={(tagId) => onTagRemove('technology', tagId)}
          onSearch={(event) => onTagSearch('technology', solution.id, event)}
          customKey={'technologies' + solution.id}
        />
      ),
      Sum: sumOfConstraints(solution),
      ...factorsData,
    });
  });
  return rows;
};

export const addFactorColumns = (secondTableColumns, factors, openEditModal, deleteFactor) => {
  const columns = [...secondTableColumns];
  const sumColumn = columns.pop();

  factors.map((factor) => {
    columns.push({
      title: (
        <div className='factorColumn gray-column-header' style={{ display: 'flex', justifyContent: 'space-between' }}>
          {factor?.factor_name}
          <div>
            <EditOutlined
              onClick={() => openEditModal(factor?.factor_name, factor?.id)}
              className='edit-icon'
              style={{ marginLeft: '8px' }}
            />
            <DeleteOutlined
              onClick={() => deleteFactor(factor?.id)}
              className='edit-icon'
              style={{ marginLeft: '4px' }}
            />
          </div>
        </div>
      ),
      dataIndex: factor?.id,
      key: factor?.id,
      className: 'gray-column-header',
    });
  });

  columns.push(sumColumn);
  return columns;
};
