import Typography from 'src/components/typography/typography';
import React, { useEffect, useState } from 'react';
import './index.css';
import { Divider, Input, Table } from 'antd';
import { NavigationFooter } from 'src/components';
import { hooks, labels, local, routes } from 'src/common';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/button';
import { projectActions } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { send_events } from 'src/sockets';
import { fetchInfluences, fetchProjectBreakthoughsRisksOutputs, } from 'src/redux/reducers/influencesSlice';
import BreakthroughsRisksOutput from './outputsModal';
import { keys } from 'lodash';
import { columns, InfluenceTypes, materialColumns, QUESTIONS } from './constants';
import { generateParticipantsData } from './helpers';
import { selectTypingDataByPage } from 'src/redux/reducers/typingSlice';
import { PAGE_NAMES } from 'src/common/constants';
import { useSendJsonMessage } from 'src/sockets/useSendJsonMessage';
import useSendTypingEvents from 'src/common/useSendTypingEvents';

const BreakthroughsAndRisks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [outputToUpdate, setOutputToUpdate] = useState(null);
  const {triggerStartTyping, triggerStopTyping} = useSendTypingEvents();
  const outputs = useSelector((state) => state.influences.outputs);
  const typingUsers = useSelector(selectTypingDataByPage(PAGE_NAMES.BREAKTHROUGH_AND_RISKS));
  const loggedUserId = local.getUser().id;

  const [inputValues, setInputValues] = useState({
    [InfluenceTypes.BREAKTHROUGH]: '',
    [InfluenceTypes.BENEFIT]: '',
    [InfluenceTypes.IMPACT]: '',
    [InfluenceTypes.FRICTION]: '',
    [InfluenceTypes.FINANCIAL_RISK]: '',
    [InfluenceTypes.LIMITING_FACTOR]: '',
  });

  const handleInputChange = (text, field) => {
    console.log(text, field);
    setInputValues({
      ...inputValues,
      [field]: text,
    });
  };

  const {influences, loading: influencesAndOutputsLoading} = useSelector(
    (state) => state.influences,
  );

  useEffect(() => {
    const values = Object.fromEntries(
      Object.entries(InfluenceTypes).map(([key, value]) => [
        value,
        influences?.[value]?.[loggedUserId]?.detail,
      ]),
    );

    setInputValues(values);
  }, [influences, loggedUserId]);

  const project = useSelector((state) => state.project.selected);
  const {id} = useParams();
  const {sendJsonMessage} = useSendJsonMessage();

  const createProjectInfluence = (value, inType) =>
    sendJsonMessage({
      type: send_events.SAVE_PROJECT_INFLUENCE,
      data: {
        detail: value,
        in_type: inType,
      },
    });

  const handleAddButtonClick = () => {
    setIsModalVisible(true);
    setOutputToUpdate(null);
  };

  const handleEditButtonClick = (output) => {
    setIsModalVisible(true);
    setOutputToUpdate(output);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setOutputToUpdate(null);
  };

  useEffect(() => {
    dispatch(fetchProjectBreakthoughsRisksOutputs(id));
    dispatch(fetchInfluences(id));
    dispatch(projectActions.getProject(id));
  }, [id]);

  hooks.usePageTitle(labels.BREAKTHROUGH_AND_RISK);
  hooks.useScrollToTop();

  return (
    <>
      <div className='breakthroughsContainerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider/>
        <Typography tag='p' fontSize='30px' fontWeight={500} blue>
          RESOURCE MAP | Breakthroughs & Risks
        </Typography>
        <Typography fontSize='14px' fontStyle='italic'>
          Fill out this INPUT section, concentrating on looser brainstorming and discussion. Place
          every idea here, you will clean it up later.
        </Typography>
        {keys(InfluenceTypes).map((key) => (
          <div key={key} className='questionContainer'>
            <Typography fontSize='18px' bold tag='p' blue>
              {QUESTIONS[key]}
            </Typography>
            <Table
              showHeader={false}
              columns={materialColumns}
              loading={influencesAndOutputsLoading}
              dataSource={generateParticipantsData(
                project ? project.userMap : {},
                InfluenceTypes[key],
                influences,
                typingUsers,
              )}
              pagination={false}
              components={{
                body: {
                  // eslint-disable-next-line no-unused-vars
                  cell: ({onMouseEnter, onMouseLeave, ...restProps}) => <td {...restProps} />,
                },
              }}
            />
            <div className='inputContainer'>
              <Input
                className='inputStyles'
                name={InfluenceTypes[key]}
                value={inputValues[InfluenceTypes[key]]}
                placeholder='Enter your response here...'
                onPressEnter={(e) => createProjectInfluence(e.target.value, InfluenceTypes[key])}
                onBlur={(e) => {
                  triggerStopTyping({
                    page: PAGE_NAMES.BREAKTHROUGH_AND_RISKS,
                    inType: InfluenceTypes[key],
                  });
                  createProjectInfluence(e.target.value, InfluenceTypes[key]);
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, InfluenceTypes[key]);
                  triggerStartTyping({
                    page: PAGE_NAMES.BREAKTHROUGH_AND_RISKS,
                    inType: InfluenceTypes[key],
                  });
                }}
              />
            </div>
          </div>
        ))}
        <BreakthroughsRisksOutput
          project_id={id}
          visible={isModalVisible}
          onClose={handleModalClose}
          outputToUpdate={outputToUpdate}
        />
        <Typography tag='h2' fontSize='24px' bold blue>
          OUTPUT
        </Typography>
        <Typography grey fontStyle='italic' tag='p'>
          Challenge & Opportunity Map: List out and describe in detail 3 challenges and 3
          opportunities related to this opportunity.
        </Typography>
        <Table
          columns={columns}
          dataSource={outputs}
          loading={influencesAndOutputsLoading}
          pagination={false}
          onRow={(record) => ({onClick: () => handleEditButtonClick(record)})}
        />
        <div className='addOutputButtonWrapper'>
          {outputs.length < 3 && <Button onClick={handleAddButtonClick}>Add Output</Button>}
        </div>
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}/users-beneficiaries`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/problem-canvas`)}
      />
    </>
  );
};

export default BreakthroughsAndRisks;
