import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  typingUsers: {},
};

const typingSlice = createSlice({
  name: 'typing',
  initialState: INITIAL_STATE,
  reducers: {
    resetTypingSlice: () => INITIAL_STATE,
    startTyping: (state, action) => {
      const { user_id, ...rest } = action.payload;
      state.typingUsers[user_id] = {
        ...rest,
      };
    },
    stopTyping: (state, action) => {
      const { user_id } = action.payload;
      delete state.typingUsers[user_id];
    },
  },
});

export const { resetTypingSlice, startTyping, stopTyping } = typingSlice.actions;

export const selectTypingDataByPage = (pageName) => (state) => {
  const typingUsers = state?.typing?.typingUsers;
  const typingDataByPage = {};

  for (const userId in typingUsers) {
    if (Object.prototype.hasOwnProperty.call(typingUsers, userId)) {
      const userData = typingUsers[userId];
      if (userData && userData.page === pageName) {
        typingDataByPage[userId] = userData;
      }
    }
  }

  return typingDataByPage;
};

export const selectTypingUsers = (state) => state.typing.typingUsers;

export default typingSlice.reducer;
