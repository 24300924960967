import { AutoComplete, Modal, message } from 'antd';
import {labels} from './constants';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { api } from 'src/api';
import { useParams } from 'react-router-dom';
import { globals, utils, errors } from 'src/common';

const AddFactorsModal = ({ isModalOpen, handleFactorCreation, onClose }) => {
  const [selectedFactor, setSelectedFactor] = useState('');
  const [factorSuggestions, setFactorSuggestions] = useState([]);
  const { id } = useParams();

  const debouncedGetFactorSuggestions = debounce(async (event) => {
    const response = await api.getFactorSuggestions(event, id);
    setFactorSuggestions(
      response.results.map((option) => ({
        key: option['id'],
        value: option['name'],
        label: option['name'],
      })),
    );
  }, 300);

  return (
    <Modal
      title='Add Factor'
      open={isModalOpen}
      okText={'Add'}
      onOk={() => {
        selectedFactor ? handleFactorCreation(selectedFactor) : message.error(labels.FACTOR_NOT_SELECTED);
        selectedFactor && onClose();
        setSelectedFactor('');
      }}
      onCancel={() => {
        setSelectedFactor('');
        onClose();
      }}
    >
      <AutoComplete
        showSearch={true}
        placeholder='Select or create a factor'
        onKeyDown={(e) => {
          utils.inputLimiter(e);
        }}
        options={factorSuggestions}
        onChange={(e) => e.length <= globals.MAX_TAG_LENGTH ? setSelectedFactor(e) : message.error(errors.CHARACTER_LIMIT_REACHED)}
        value={selectedFactor}
        onSearch={debouncedGetFactorSuggestions}
        filterOption={false}
      />
    </Modal>
  );
};

export default AddFactorsModal;
