import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions, userActions } from 'src/redux/actions';
import 'src/pages/project/_project.scss';
import { clauses, hooks, labels, local, routes } from 'src/common';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationFooter, TagRender } from 'src/components';
import Typography from 'src/components/typography/typography';
import { Form, Input, Select, Spin } from 'antd';

function Project({setCanNavigate, nextPage}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const {selected: project} = useSelector((state) => state.project);
  const loggedUser = local.getUser();
  const isOwner = project?.created_by.id === loggedUser?.id;
  const {users, userMap} = useSelector((state) => state.user);
  const {id} = useParams();
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const participants = Form.useWatch('participants', form);
  const isNewProject = !id;

  const navigateToOpportunities = (projectID) => {
    navigate(nextPage ? nextPage : `${routes.PROJECT}/${projectID}${routes.OPPORTUNITIES}`);
    setCanNavigate(true);
  };

  const addParticipant = (userID) => {
    const user = userMap[userID];
    const updatedParticipants = [...participants, {label: user.representation, value: user.id}];
    form.setFieldValue('participants', updatedParticipants);
  };

  const removeParticipant = (userID) => {
    const updatedParticipants = participants.filter((participant) => participant.value !== userID);
    form.setFieldValue('participants', updatedParticipants);
  };

  const handleFormChange = () => {
    setCanNavigate(false);
    setIsFormChanged(true);
  };

  const onFinish = async (values) => {
    const projectDetails = {
      name: values.name,
      participants: values.participants.map((participant) => participant.value),
    };

    if (isNewProject) {
      const {
        payload: {id: newProjectId},
      } = await dispatch(projectActions.createProject(projectDetails));
      navigateToOpportunities(newProjectId);
    } else {
      if (isFormChanged && isOwner) {
        dispatch(projectActions.updateProject({id: project.id, data: projectDetails}));
      }
      navigateToOpportunities(project.id);
    }
  };

  useEffect(() => {
    if (id && project) {
      const participants = project.participants.map((participant) => ({
        label: participant.representation,
        value: participant.id,
      }));
      form.setFieldsValue({name: project.name, participants: participants});
    }
  }, [project]);

  useEffect(() => {
    const fetchProject = async () => {
      dispatch(userActions.getUsers());
      if (id) {
        await dispatch(projectActions.getProject(id));
      }
      setLoading(false);
    };
    fetchProject();
  }, [id]);

  useEffect(() => {
    if (nextPage) {
      form.submit();
    }
  }, [nextPage]);

  hooks.usePageTitle(labels.PROJECT);

  return (
    <>
      <div className='project'>
        <Typography fontSize='48px' fontWeight={600} blue className='title'>
          {labels.WELCOME}
        </Typography>
        {isLoading ? (
          <Spin size='large'/>
        ) : (
          <div className='projectContainer'>
            <Form onFinish={onFinish} form={form} initialValues={{name: '', participants: []}}
                  onValuesChange={handleFormChange}>
              <Typography fontSize='16px' fontWeight={400} black>
                {clauses.ENTER_PROJECT_NAME}
              </Typography>
              <Form.Item
                name='name'
                rules={[
                  {
                    required: true,
                    message: clauses.PROJECT_NAME_REQUIRED,
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder='Enter project name'
                  disabled={!isOwner && !isNewProject}
                />
              </Form.Item>

              <Typography fontSize='16px' fontWeight={400} black>
                {clauses.ENTER_PARTICIPANTS}
              </Typography>
              <Form.Item name='participants'>
                <Select
                  mode='multiple'
                  tagRender={TagRender}
                  style={{width: '100%'}}
                  size='large'
                  onSelect={addParticipant}
                  onDeselect={removeParticipant}
                  options={users
                    .filter((user) => user.id !== loggedUser?.id)
                    .map((user) => ({
                      label: user.representation,
                      value: user.id,
                    }))}
                  optionFilterProp='label'
                  disabled={!isOwner && !isNewProject}
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
      <NavigationFooter
        onBack={() => navigate(routes.HOME)}
        onNext={() => {
          form.submit();
        }}
      />
    </>
  );
}

export default Project;
