import React, { useState } from 'react';
import { Collapse, Form } from 'antd';
import { ReadyState } from 'react-use-websocket';
import { send_events, useWebSocketContext } from 'src/sockets';
import { hooks } from 'src/common';
import TextArea from 'antd/es/input/TextArea';

const { Panel } = Collapse;

const QuestionnairePage = ({ answers }) => {
  const { sendJsonMessage, readyState } = useWebSocketContext();
  const [answerValues, setAnswerValues] = useState(answers);

  const whyQuestions = [
    { question: 'How is it done today?', answerType: 'current_practice' },
    { question: 'What are the limits of current practice?', answerType: 'current_practice_limits' },
  ];

  const whatQuestions = [
    { question: 'What are you trying to do?', answerType: 'goal' },
    { question: 'Why do you think it will be successful?', answerType: 'success_reason' },
  ];

  const whoQuestions = [
    {
      question: "If you're successful, what difference will it make?",
      answerType: 'success_difference',
    },
  ];

  const howQuestions = [
    {
      question: 'Map of Users, Resource Requirements, Risks and Workarounds, Milestones.',
      answerType: 'resource_map',
    },
    { question: 'What are the risks and payoffs?', answerType: 'risks_and_payoffs' },
    { question: 'How much will it cost?', answerType: 'cost_estimate' },
    { question: 'How long will it take?', answerType: 'time_estimate' },
    { question: 'How to measure success?', answerType: 'success_measurement' },
  ];

  const updateSummary = (answerType, value) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.UPDATE_SUMMARY,
        data: { [answerType]: value },
      });
    }
  };

  const handleTextAreaUpdate = (answerType, value) =>
    setAnswerValues((prevAnswers) => ({
      ...prevAnswers,
      [answerType]: value,
    }));

  hooks.useScrollToTop();

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Form style={{ width: '100%' }} onSubmit={() => {}}>
        <Collapse accordion>
          <Panel header='Why?' key='why'>
            {whyQuestions.map((whyQuestion) => (
              <Form.Item
                key={whyQuestion.question}
                label={whyQuestion.question}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  placeholder='Enter your answer'
                  value={
                    answerValues[whyQuestion.answerType] !== undefined
                      ? answerValues[whyQuestion.answerType]
                      : answers[whyQuestion.answerType]
                  }
                  onChange={(e) => handleTextAreaUpdate(whyQuestion.answerType, e.target.value)}
                  onBlur={(e) => updateSummary(whyQuestion.answerType, e.target.value)}
                  autoSize
                />
              </Form.Item>
            ))}
          </Panel>
          <Panel header='Who?' key='who'>
            {whoQuestions.map((whoQuestion) => (
              <Form.Item
                key={whoQuestion.question}
                label={whoQuestion.question}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  placeholder='Enter your answer'
                  value={
                    answerValues[whoQuestion.answerType] !== undefined
                      ? answerValues[whoQuestion.answerType]
                      : answers[whoQuestion.answerType]
                  }
                  onChange={(e) => handleTextAreaUpdate(whoQuestion.answerType, e.target.value)}
                  onBlur={(e) => updateSummary(whoQuestion.answerType, e.target.value)}
                  autoSize
                />
              </Form.Item>
            ))}
          </Panel>
          <Panel header='What?' key='what'>
            {whatQuestions.map((whatQuestion) => (
              <Form.Item
                key={whatQuestion.question}
                label={whatQuestion.question}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  placeholder='Enter your answer'
                  value={
                    answerValues[whatQuestion.answerType] !== undefined
                      ? answerValues[whatQuestion.answerType]
                      : answers[whatQuestion.answerType]
                  }
                  onChange={(e) => handleTextAreaUpdate(whatQuestion.answerType, e.target.value)}
                  onBlur={(e) => updateSummary(whatQuestion.answerType, e.target.value)}
                  autoSize
                />
              </Form.Item>
            ))}
          </Panel>
          <Panel header='How?' key='how'>
            {howQuestions.map((howQuestion) => (
              <Form.Item
                key={howQuestion.question}
                label={howQuestion.question}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  placeholder='Enter your answer'
                  value={
                    answerValues[howQuestion.answerType] !== undefined
                      ? answerValues[howQuestion.answerType]
                      : answers[howQuestion.answerType]
                  }
                  onChange={(e) => handleTextAreaUpdate(howQuestion.answerType, e.target.value)}
                  onBlur={(e) => updateSummary(howQuestion.answerType, e.target.value)}
                  autoSize
                />
              </Form.Item>
            ))}
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default QuestionnairePage;
