import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';

export const fetchPotentialSolutions = createAsyncThunk(
  'solutionCanvas/fetchPotentialSolutions',
  async (id) => {
    return await api.getPotentialSolutions(id);
  },
);

export const fetchProjectFactors = createAsyncThunk(
  'solutionCanvas/fetchProjectFactors',
  async (id) => {
    return await api.getProjectFactors(id);
  },
);

export const fetchFindingsPlot = createAsyncThunk(
  'findingsPlot/fetchFindingsPlot',
  async (id) => {
    return await api.getPotentialSolutionFindingPlot(id);
  },
);

const INITIAL_STATE = {
  potentialSolutions: [],
  potentialSolutionsLoading: false,
  factors: [],
  factorsLoading: false,
  findingsPlot: {},
  fPLoading: false,
};

const solutionCanvasSlice = createSlice({
  name: 'solutionCanvas',
  initialState: INITIAL_STATE,
  reducers: {
    resetSolutionCanvasSlice: () => INITIAL_STATE,
    addPotentialSolution: (state, action) => {
      const {id, solution_name} = action.payload;
      state.potentialSolutions.push({id, solution_name, ps_factors: {}});
    },
    updatePotentialSolution: (state, {payload}) => {
      const ps_id = payload.id;
      const potentialSolutions = [...state.potentialSolutions];
      const index = potentialSolutions.findIndex((solution) => solution.id === ps_id);
      if (index !== -1) {
        Object.assign(potentialSolutions[index], payload);
        state.potentialSolutions = potentialSolutions;
      }
      const {solution_name, cost, time, impact} = payload;
      if (cost && time && impact) {
          state.findingsPlot[ps_id] = {solution_name, cost, time, impact};
      }else{
        delete state.findingsPlot[ps_id];
      }
    },
    updatePsFactor: (state, action) => {
      const potentialSolutions = [...state.potentialSolutions];
      const psFactor = action.payload;
      const index = potentialSolutions.findIndex(
        (solution) => solution.id === psFactor.potential_solution,
      );
      if (index !== -1) {
        potentialSolutions[index].ps_factors[psFactor.project_factor] = {
          id: psFactor.id,
          detail: psFactor.detail,
        };
        state.potentialSolutions = potentialSolutions;
      }
    },
    addProjectFactors: (state, action) => {
      state.factors.push(action.payload);
    },
    updateProjectFactor: (state, action) => {
      const factors = [...state.factors]
      const updatedFactor = action.payload;
      const index = factors.findIndex(
        (factor) => factor.id === updatedFactor.id,
      );
      if (index !== -1) {
        factors[index] = updatedFactor
        state.factors = factors;
      }
    },
    deleteProjectFactor: (state, action) => {
      const factors = [...state.factors]
      const deletedFactor = action.payload
      state.factors = factors.filter(factor => factor.id !== deletedFactor.id)
    },
    addEntity: (state, action) => {
      const potentialSolutions = [...state.potentialSolutions];
      const entity = action.payload;
      const index = potentialSolutions.findIndex(
        (solution) => solution.id === entity.potential_solution,
      );
      if (index !== -1) {
        potentialSolutions[index].ps_entities.push({
          id: entity.id,
          entity_name: entity.entity_name,
          created_by: entity.created_by,
        });
        state.potentialSolutions = potentialSolutions;
      }
    },
    addTechnology: (state, action) => {
      const potentialSolutions = [...state.potentialSolutions];
      const technology = action.payload;
      const index = potentialSolutions.findIndex(
        (solution) => solution.id === technology.potential_solution,
      );
      if (index !== -1) {
        potentialSolutions[index].ps_technologies.push({
          id: technology.id,
          technology_name: technology.technology_name,
          created_by: technology.created_by,
        });
        state.potentialSolutions = potentialSolutions;
      }
    },
    removeEntity: (state, action) => {
      const potentialSolutions = [...state.potentialSolutions];
      const entity = action.payload;
      const index = potentialSolutions.findIndex(
        (solution) => solution.id === entity.potential_solution,
      );
      if (index !== -1) {
        potentialSolutions[index].ps_entities = potentialSolutions[index].ps_entities.filter(
          (ps_entity) => ps_entity.id !== entity.id,
        );
        state.potentialSolutions = potentialSolutions;
      }
    },
    removeTechnology: (state, action) => {
      const potentialSolutions = [...state.potentialSolutions];
      const technology = action.payload;
      const index = potentialSolutions.findIndex(
        (solution) => solution.id === technology.potential_solution,
      );
      if (index !== -1) {
        potentialSolutions[index].ps_technologies = potentialSolutions[
          index
          ].ps_technologies.filter((ps_technology) => ps_technology.id !== technology.id);
        state.potentialSolutions = potentialSolutions;
      }
    },
  },

  extraReducers: {
    [fetchPotentialSolutions.pending]: (state) => {
      state.potentialSolutionsLoading = true;
    },
    [fetchPotentialSolutions.rejected]: (state) => {
      state.potentialSolutionsLoading = false;
    },
    [fetchPotentialSolutions.fulfilled]: (state, action) => {
      state.potentialSolutionsLoading = false;
      state.potentialSolutions = action.payload;
    },
    [fetchProjectFactors.pending]: (state) => {
      state.factorsLoading = true;
    },
    [fetchProjectFactors.rejected]: (state) => {
      state.factorsLoading = false;
    },
    [fetchProjectFactors.fulfilled]: (state, action) => {
      state.factorsLoading = false;
      state.factors = action.payload;
    },
    [fetchFindingsPlot.pending]: (state) => {
      state.fPLoading = true;
    },
    [fetchFindingsPlot.rejected]: (state) => {
      state.fPLoading = false;
    },
    [fetchFindingsPlot.fulfilled]: (state, action) => {
      state.fPLoading = false;
      state.findingsPlot = action.payload;
    },
  },
});

export const {
  resetSolutionCanvasSlice,
  addPotentialSolution,
  addProjectFactors,
  updateProjectFactor,
  deleteProjectFactor,
  updatePotentialSolution,
  updatePsFactor,
  addEntity,
  addTechnology,
  removeEntity,
  removeTechnology,
} = solutionCanvasSlice.actions;
export default solutionCanvasSlice.reducer;
