import { UpOutlined } from '@ant-design/icons';
import { Checkbox, Col, Collapse, Empty, Row, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { projectActions, userActions } from 'src/redux/actions';
import Typography from '../../components/typography/typography';
import Button from '../../components/button';
import { send_events, useWebSocketContext } from 'src/sockets';
import { ReadyState } from 'react-use-websocket';
import { NavigationFooter } from 'src/components';
import { hooks, labels, local, routes } from 'src/common';
import './index.css';

const OpportunitySelection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sendJsonMessage, readyState } = useWebSocketContext();
  const loggedUser = local.getUser();
  const project = useSelector((state) => state.project.selected);
  const criteria = useSelector((state) => state.project.criteria);
  const selectedOpportunities = useSelector((state) => state.project.selectedOpportunity);

  const updateCriterion = (opportunityId, criterionId, isSelected) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.UPDATE_OPPORTUNITY_CRITERION,
        data: {
          opportunity_id: opportunityId,
          criterion_id: criterionId,
          is_selected: isSelected,
        },
      });
    }
  };

  const selectBigOpportunity = (opportunityId) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.SELECT_BIG_OPPORTUNITY,
        data: {
          id: opportunityId,
        },
      });
    }
  };

  const customExpandIcon = (props) => {
    // eslint-disable-next-line react/prop-types
    const { isActive } = props;
    return (
      <div className='expandIconContainer'>
        <UpOutlined rotate={isActive ? 0 : 180} />
      </div>
    );
  };

  useEffect(() => {
    project
      ? dispatch(projectActions.getSelectedOpportunities(project.id))
      : dispatch(projectActions.getProject(id));
  }, [dispatch, id, project]);

  useEffect(() => {
    dispatch(userActions.getUsers());
    dispatch(projectActions.getCriteria());
  }, []);

  const panels = selectedOpportunities.map((item, index) => ({
    label: `${index + 1} - ${item.name}`,
    key: item.id,
    children: (
      <div className='collapseContent'>
        {criteria.map((criterion) => {
          const selectedBy = item.criteria[criterion.id];
          return (
            <Row key={criterion.id} className='checkBoxRow'>
              <Col>
                <Checkbox
                  checked={selectedBy?.includes(loggedUser.id)}
                  onChange={(e) => updateCriterion(item.id, criterion.id, e.target.checked)}
                >
                  <Typography>{criterion.name}</Typography>
                </Checkbox>
              </Col>
              <Col>
                {selectedBy?.map((userId) => {
                  const representation =
                    userId !== loggedUser.id && project?.userMap[userId]?.representation;
                  return representation ? <Tag key={userId}>{representation}</Tag> : null;
                })}
              </Col>
            </Row>
          );
        })}
      </div>
    ),
    extra: (
      <Button
        onClick={(event) => {
          event.stopPropagation();
          selectBigOpportunity(item.id);
        }}
        disabled={project?.big_opportunity?.id === item.id}
      >
        {project?.big_opportunity?.id === item.id ? 'Selected' : 'Select'}
      </Button>
    ),
  }));

  hooks.usePageTitle(labels.SELECTED_BIG_OPPORTUNITY);

  return (
    <>
      <div className='selectOpportunityContainerStyle'>
        <div>
          <Typography black bold fontSize='24px' tag='h1'>
            Opportunities
          </Typography>
          <Typography fontSize='14px' fontStyle='italic' lineHeight='21px'>
            If there are many opportunities, the opportunity that is the most feasible, impactful,
            and actionable can be chosen (please create an affinity diagram or cluster to enhance
            analysis, if you can) Please choose <b>ONE</b> that you will investigate further!
          </Typography>
          {selectedOpportunities.length ? (
            <Collapse
              expandIcon={customExpandIcon}
              expandIconPosition='right'
              items={panels}
              ghost
            />
          ) : (
            <div className='emptyContainerStyles'>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="If you haven't liked any opportunities yet, there won't be any listed here. Feel free to go back to the previous page and start liking some opportunities."
              />
            </div>
          )}
        </div>
        <div>
          <Typography fontSize='24px'>Big Opportunity to investigate further:</Typography>
          <Typography fontSize='30px' fontWeight={500} blue tag='h2'>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </div>
      </div>

      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}/opportunities`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/people-and-organizations`)}
      />
    </>
  );
};

export default OpportunitySelection;
