import React from 'react';
import 'src/components/navigationFooter/_navigationFooter.scss';
import { labels } from 'src/common';
import Button from '../button';

function NavigationFooter({
  showBack = true,
  showNext = true,
  backDisabled = false,
  nextDisabled = false,
  onBack = () => {},
  onNext = () => {},
}) {
  return (
    <div className='navFooter'>
      {showBack && (
        <Button className='backBtn' onClick={onBack} disabled={backDisabled}>
          {labels.BACK}
        </Button>
      )}
      {showNext && (
         <div style={{ marginLeft: 'auto' }}>
          <Button className='nextBtn' onClick={onNext} disabled={nextDisabled}>
            {labels.NEXT}
          </Button>
         </div>
      )}
    </div>
  );
}

export default NavigationFooter;
