import React from 'react';
import Typography from 'src/components/typography/typography';

export const generateParticipantsData = (participants, influenceType, influences, typingUsers) => {
  const userObject = JSON.parse(localStorage.getItem('user'));
  const userId = userObject?.id;
  return Object.entries(participants)
    .filter(([_, participant]) => participant?.id !== userId)
    .map(([_, participant], index) => {
      const isTyping = typingUsers[participant?.id]?.inType == influenceType;
      const detail = influences?.[influenceType]?.[participant?.id]?.detail;
      const isCurrentUser = userId == participant?.id;
      return {
        key: '1',
        participant: (
          <Typography color='#808080' fontSize='12px' fontStyle='italic'>
            {participant?.first_name
              ? `${participant?.first_name} ${participant?.last_name}`
              : participant?.email}
          </Typography>
        ),
        responses: (
          <Typography style={{opacity: detail ? 1 : 0.5}} fontSize='14px'>
            {!isCurrentUser && isTyping ? 'is typing...' : detail || 'Not added yet'}
          </Typography>
        ),
      };
    });
};
