import Typography from 'src/components/typography/typography';
import React, { useEffect } from 'react';
import './index.css';
import { Divider } from 'antd';
import { NavigationFooter } from 'src/components';
import { hooks, labels, routes } from 'src/common';
import { useNavigate, useParams } from 'react-router-dom';
import { projectActions } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSummary } from 'src/redux/reducers/summarySlice';
import QuestionnairePage from './QuestionnairePage';

const Summary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const project = useSelector((state) => state.project.selected);
  const answers = useSelector((state) => state.summary.answers);
  const { id } = useParams();

  useEffect(() => {
    dispatch(projectActions.getProject(id));
    dispatch(fetchSummary(id));
  }, [id]);

  hooks.usePageTitle(labels.SUMMARY);

  return (
    <>
      <div className='summaryContainerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider />
        <Typography tag='p' fontSize='30px' fontWeight={500} blue>
          Summary of Findings
        </Typography>
        <Typography fontSize='14px' fontStyle='italic' tag='p'>
          Please create one-pager about solutions and be prepared to present. Some guiding questions
          below.
        </Typography>

        <QuestionnairePage answers={answers} />
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}/findings-plot`)}
        showNext={false}
      />
    </>
  );
};

export default Summary;
