import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { send_events } from 'src/sockets';
import { useSendJsonMessage } from 'src/sockets/useSendJsonMessage';

const useSendTypingEvents = () => {
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);
  const { sendJsonMessage } = useSendJsonMessage();

  const triggerStartTyping = (typingMessageData = {}) => {
    if (isEmpty(typingMessageData)) return;
    if (!isTyping) {
      setIsTyping(true);
      sendJsonMessage({ type: send_events.START_TYPING, data: typingMessageData });
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      sendJsonMessage({ type: send_events.STOP_TYPING, data: typingMessageData });
    }, 5000);
  };

  const triggerStopTyping = (typingMessageData = {}) => {
    if (isTyping && !isEmpty(typingMessageData)) {
      clearTimeout(typingTimeoutRef.current);
      setIsTyping(false);
      sendJsonMessage({ type: send_events.STOP_TYPING, data: typingMessageData });
    }
  };

  return { triggerStartTyping, triggerStopTyping };
};

export default useSendTypingEvents;
