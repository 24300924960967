import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'src/api';

const INITIAL_STATE = {
  influences: [],
  outputs: [],
  loading: false,
  error: null,
};

export const fetchInfluences = createAsyncThunk(
  'influences/fetchInfluences',
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await api.getProjectInfluences(projectId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProjectBreakthoughsRisksOutputs = createAsyncThunk(
  'influences/fetchProjectBreakthoughsRisksOutputs',
  async (project_id) => {
    try {
      const response = await api.getProjectBreakthoughsRisksOutputs(project_id);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
);

const influencesSlice = createSlice({
  name: 'influences',
  initialState: INITIAL_STATE,
  reducers: {
    resetInfluenceSlice: () => INITIAL_STATE,
    deleteOutput(state, action) {
      const { id: idToDelete } = action.payload;
      state.outputs = state.outputs.filter((item) => item.id !== idToDelete);
    },
    addOutput(state, action) {
      state.outputs.push(action.payload);
    },
    updateOutput(state, action) {
      state.outputs = state.outputs.map((output) => {
        if (output.id == action.payload.id) {
          return action.payload;
        } else {
          return output;
        }
      });
    },
    updateInfluencesOnCreate(state, action) {
      const newInfluence = action.payload;
      const { in_type, created_by } = newInfluence;

      if (!state.influences[in_type]) {
        state.influences[in_type] = {};
      }

      if (!state.influences[in_type][created_by]) {
        state.influences[in_type][created_by] = {};
      }

      state.influences[in_type][created_by] = newInfluence;
    },
    deleteInfluence(state, action) {
      const { id, created_by, inf_type } = action.payload;
      const updatedInfluences = { ...state.influences };

      if (updatedInfluences[inf_type] && updatedInfluences[inf_type][created_by]) {
        updatedInfluences[inf_type][created_by] = updatedInfluences[inf_type][created_by].filter(
          (influence) => influence.id !== id,
        );

        // If there are no influences left for a particular type and creator, remove the empty array
        if (updatedInfluences[inf_type][created_by].length === 0) {
          delete updatedInfluences[inf_type][created_by];
        }

        // If there are no influences left for a particular type, remove the empty object
        if (Object.keys(updatedInfluences[inf_type]).length === 0) {
          delete updatedInfluences[inf_type];
        }

        // Update the state
        state.influences = updatedInfluences;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInfluences.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchInfluences.fulfilled, (state, action) => {
      state.influences = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchInfluences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectBreakthoughsRisksOutputs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProjectBreakthoughsRisksOutputs.fulfilled, (state, action) => {
      state.outputs = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchProjectBreakthoughsRisksOutputs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetInfluenceSlice, updateInfluencesOnCreate, deleteInfluence, deleteOutput, addOutput, updateOutput } =
  influencesSlice.actions;

export default influencesSlice.reducer;
