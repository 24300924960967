export const project_entity_types = {
  0: 'Companies',
  1: 'Organizations',
  2: 'Government_Organizations',
  3: 'Startups',
  4: 'Stakeholders',
  5: 'Customers',
  6: 'Interest groups',
  7: 'Investors',
  8: 'Data',
  9: 'Information',
  10: 'Published materials',
};

export const organizationColumns = [
  {
    title: '',
    dataIndex: 'Participant',
    key: 'participant',
    className: 'first-column column-max-width',
    width: '10%',
  },
  {
    title: 'Companies',
    dataIndex: 'Companies',
    className: 'column-max-width',
    key: '0',
  },
  {
    title: 'Organizations',
    dataIndex: 'Organizations',
    className: 'column-max-width',
    key: '1',
  },
  {
    title: 'Government organizations',
    dataIndex: 'Government_Organizations',
    className: 'column-max-width',
    key: '2',
  },
  {
    title: 'Startups',
    dataIndex: 'Startups',
    className: 'column-max-width',
    key: '3',
  },
];

export const peopleColumns = [
  {
    title: '',
    dataIndex: 'Participant',
    key: 'participant',
    className: 'first-column column-max-width',
    width: '10%',
  },
  {
    title: 'Stakeholders',
    dataIndex: 'Stakeholders',
    className: 'column-max-width',
    key: '4',
  },
  {
    title: 'Customers',
    dataIndex: 'Customers',
    className: 'column-max-width',
    key: '5',
  },
  {
    title: 'Interest groups',
    dataIndex: 'Interest groups',
    className: 'column-max-width',
    key: '6',
  },
  {
    title: 'Investors',
    className: 'column-max-width',
    dataIndex: 'Investors',
    key: '7',
  },
];

export const materialColumns = [
  {
    title: '',
    dataIndex: 'Participant',
    key: 'participant',
    className: 'first-column column-max-width',
    width: '10%',
  },
  {
    title: 'Data',
    dataIndex: 'Data',
    className: 'column-max-width',
    key: '8',
  },
  {
    title: 'Information',
    dataIndex: 'Information',
    className: 'column-max-width',
    key: '9',
  },
  {
    title: 'Published materials',
    dataIndex: 'Published materials',
    className: 'column-max-width',
    key: '10',
  },
];

export const outputColumns = [
  {
    title: '#',
    dataIndex: 'Entity_Number',
    className: 'first-column',
    width: '10%',
  },
  {
    title: 'Name and Description',
    dataIndex: 'Entity_Name',
    width: '30%',
  },
  {
    title: 'How it is related, how will you be different',
    dataIndex: 'Justification',
    width: '60%',
  },
];

export const pageSections = (rowsData) => {
  return [
    {
      id: 'org_col',
      heading: 'Fill out organizations related to the big opportunity.',
      tableColumns: organizationColumns,
      tableData: rowsData,
    },
    {
      id: 'people_col',
      heading: 'People related to big opportunity',
      tableColumns: peopleColumns,
      tableData: rowsData,
    },
    {
      id: 'material_col',
      heading: 'Material related to big opportunity',
      tableColumns: materialColumns,
      tableData: rowsData,
    },
  ];
};
