export const send_events = {
  CREATE_BREAKTHROUGH_AND_RISK_OUTPUT: 'create_breakthrough_and_risk_output',
  CREATE_OPPORTUNITY: 'create_opportunity',
  CREATE_PEOPLE_ORG_OUTPUT: 'create_people_and_org_output',
  CREATE_POTENTIAL_SOLUTION: 'create_potential_solution',
  CREATE_PROJECT_BENEFICIARY: 'create_project_beneficiary',
  CREATE_PROJECT_ENTITY: 'create_project_entity',
  CREATE_PROJECT_FACTOR: 'create_project_factor',
  CREATE_PROJECT_LEARNING: 'create_project_learning',
  CREATE_PROJECT_SOLUTION: 'create_project_solution',
  CREATE_PROJECT_SUB_PROBLEM: 'create_project_sub_problem',
  CREATE_PROJECT_TECHNOLOGY: 'create_project_technology',
  CREATE_PS_ENTITY: 'create_ps_entity',
  CREATE_PS_TECHNOLOGY: 'create_ps_technology',
  DELETE_BREAKTHROUGH_AND_RISK_OUTPUT: 'delete_breakthrough_and_risk_output',
  DELETE_PEOPLE_ORG_OUTPUT: 'delete_people_and_org_output',
  DELETE_PROJECT_BENEFICIARY: 'delete_project_beneficiary',
  DELETE_PROJECT_ENTITY: 'delete_project_entity',
  DELETE_PROJECT_FACTOR: 'delete_project_factor',
  DELETE_PROJECT_INFLUENCE: 'delete_project_influence',
  DELETE_PROJECT_LEARNING: 'delete_project_learning',
  DELETE_PROJECT_SOLUTION: 'delete_project_solution',
  DELETE_PROJECT_TECHNOLOGY: 'delete_project_technology',
  DELETE_PROJECT_SUB_PROBLEM: 'delete_project_sub_problem',
  DELETE_PS_ENTITY: 'delete_ps_entity',
  DELETE_PS_TECHNOLOGY: 'delete_ps_technology',
  DELETE_USER_AND_BENEFICIARY_OUTPUT: 'delete_user_and_beneficiary_output',
  LIKE_OPPORTUNITY: 'like_opportunity',
  PROJECT_LEARNING_CREATED: 'project_learning_created',
  SAVE_PROJECT_INFLUENCE: 'save_project_influence',
  SAVE_PS_FACTOR: 'save_ps_factor',
  SELECT_BIG_OPPORTUNITY: 'select_big_opportunity',
  START_TYPING: 'start_typing',
  STOP_TYPING: 'stop_typing',
  UPDATE_BREAKTHROUGH_AND_RISK_OUTPUT: 'update_breakthrough_and_risk_output',
  UPDATE_OPPORTUNITY_CRITERION: 'update_opportunity_criterion',
  UPDATE_OPPORTUNITY: 'update_opportunity',
  UPDATE_PEOPLE_ORG_OUTPUT: 'update_people_and_org_output',
  UPDATE_POTENTIAL_SOLUTION: 'update_potential_solution',
  UPDATE_PROJECT_FACTOR: 'update_project_factor',
  UPDATE_PROJECT_SUB_PROBLEM: 'update_project_sub_problem',
  UPDATE_PROJECT_TECHNOLOGY: 'update_project_technology',
  UPDATE_SUMMARY: 'update_summary',
};

export const receive_events = {
  BIG_OPPORTUNITY_SELECTED: 'big_opportunity_selected',
  BREAKTHROUGH_AND_RISK_OUTPUT_CREATED: 'breakthrough_and_risk_output_created',
  BREAKTHROUGH_AND_RISK_OUTPUT_DELETED: 'breakthrough_and_risk_output_deleted',
  BREAKTHROUGH_AND_RISK_OUTPUT_UPDATED: 'breakthrough_and_risk_output_updated',
  ERROR: 'error',
  OPPORTUNITY_CREATED: 'opportunity_created',
  OPPORTUNITY_CRITERION_UPDATED: 'opportunity_criterion_updated',
  OPPORTUNITY_UPDATED: 'opportunity_updated',
  PEOPLE_ORG_OUTPUT_CREATED: 'people_and_org_output_created',
  PEOPLE_ORG_OUTPUT_DELETED: 'people_and_org_output_deleted',
  PEOPLE_ORG_OUTPUT_UPDATED: 'people_and_org_output_updated',
  POTENTIAL_SOLUTION_CREATED: 'potential_solution_created',
  POTENTIAL_SOLUTION_UPDATED: 'potential_solution_updated',
  PROJECT_BENEFICIARY_CREATED: 'project_beneficiary_created',
  PROJECT_BENEFICIARY_DELETED: 'project_beneficiary_deleted',
  PROJECT_ENTITY_CREATED: 'project_entity_created',
  PROJECT_ENTITY_DELETED: 'project_entity_deleted',
  PROJECT_FACTOR_CREATED: 'project_factor_created',
  PROJECT_FACTOR_DELETED: 'project_factor_deleted',
  PROJECT_FACTOR_UPDATED: 'project_factor_updated',
  PROJECT_INFLUENCE_SAVED: 'project_influence_saved',
  PROJECT_LEARNING_CREATED: 'project_learning_created',
  PROJECT_LEARNING_DELETED: 'project_learning_deleted',
  PROJECT_SOLUTION_CREATED: 'project_solution_created',
  PROJECT_SOLUTION_DELETED: 'project_solution_deleted',
  PROJECT_SUB_PROBLEM_CREATED: 'project_sub_problem_created',
  PROJECT_SUB_PROBLEM_UPDATED: 'project_sub_problem_updated',
  PROJECT_TECHNOLOGY_CREATED: 'project_technology_created',
  PROJECT_TECHNOLOGY_DELETED: 'project_technology_deleted',
  PROJECT_TECHNOLOGY_UPDATED: 'project_technology_updated',
  PROJECT_SUB_PROBLEM_DELETED: 'project_sub_problem_deleted',
  PS_ENTITY_CREATED: 'ps_entity_created',
  PS_ENTITY_DELETED: 'ps_entity_deleted',
  PS_FACTOR_SAVED: 'ps_factor_saved',
  PS_TECHNOLOGY_CREATED: 'ps_technology_created',
  PS_TECHNOLOGY_DELETED: 'ps_technology_deleted',
  SUMMARY_UPDATED: 'summary_updated',
  TYPING_STARTED: 'typing_started',
  TYPING_STOPPED: 'typing_stopped',
  USER_AND_BENEFICIARY_OUTPUT_CREATED: 'user_and_beneficiary_output_created',
  USER_AND_BENEFICIARY_OUTPUT_DELETED: 'user_and_beneficiary_output_deleted',
  USER_AND_BENEFICIARY_OUTPUT_UPDATED: 'user_and_beneficiary_output_updated',
  PROJECT_UPDATED: 'project_updated',
};
