import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import 'src/components/textInput/textInput.css';

let inputId = '';

const TextInput = ({ onUpdate, customKey, initialValue = null, ...rest }) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const saveData = (e) => {
    onUpdate(e.target.value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (inputId === customKey) {
      inputRef.current.focus();
    }
  }, [inputId]);

  const handleFocus = (e) => {
    inputId = customKey;
  };

  return <Input key={customKey} value={value} ref={inputRef}
                onFocus={handleFocus} onChange={handleInputChange} className='custom-input' {...rest}
                onBlur={(e) => {
                  saveData(e);
                  inputId = '';
                }}
                onPressEnter={saveData} />;
};

export default TextInput;
