import React from 'react';
import { Card, Tag } from 'antd';
import PropTypes from 'prop-types';
import Typography from 'src/components/typography/typography';
import 'src/components/TagsContainer/index.css';

const TagsContainer = ({
  tags = [],
  isViewOnly = false,
  onRemove = () => {},
  accessKey,
  isTyping = false,
}) => (
  <Card className='tagInputCard'>
    <div className='tagInputCardContent'>
      <div>
        {isTyping ? (
          <Typography color='#808080' fontSize='14px'>
            Is typing...
          </Typography>
        ) : tags?.length ? (
          tags.map((tag, index) => (
            <Tag key={index} closable={!isViewOnly} onClose={() => onRemove(tag)}>
              {tag[accessKey]}
            </Tag>
          ))
        ) : (
          <Typography color='#808080' fontSize='14px'>
            Not added yet
          </Typography>
        )}
      </div>
    </div>
  </Card>
);

TagsContainer.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isViewOnly: PropTypes.bool,
  isTyping: PropTypes.bool,
  onRemove: PropTypes.func,
  accessKey: PropTypes.string.isRequired,
};

export default TagsContainer;
