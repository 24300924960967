import Typography from 'src/components/typography/typography';
import React, { useEffect } from 'react';
import './index.css';
import { Divider } from 'antd';
import { NavigationFooter } from 'src/components';
import { hooks, labels, routes } from 'src/common';
import { useNavigate, useParams } from 'react-router-dom';
import { projectActions } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import BubbleChart from './bubbleChart';
import { fetchFindingsPlot } from 'src/redux/reducers/solutionCanvasSlice';


const FindingsPlot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project.selected);
  const {findingsPlot, fPLoading: loading} = useSelector((state) => state.solutionCanvas);
  const {id} = useParams();

  useEffect(() => {
    !project && dispatch(projectActions.getProject(id));
    dispatch(fetchFindingsPlot(id));
  }, []);

  hooks.usePageTitle(labels.FINDINGS_PLOT);
  hooks.useScrollToTop();

  return (
    <>
      <div className='findingsPlotContainerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider/>
        <Typography tag='p' fontSize='30px' fontWeight={500} blue>
          FINDINGS PLOT
        </Typography>
        <Typography fontSize='14px' fontStyle='italic' tag='p'>
          The Findings Plot demonstrates the efficiency of the option.
        </Typography>
        <Typography fontSize='14px' fontStyle='italic' tag='p'>
          The source of information is your Solution Canvas, and the largest opportunities are
          represented in the largest bubbles to represent the breadth of the impact.
        </Typography>
        <BubbleChart data={findingsPlot}/>
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}/solution-canvas`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/summary`)}
      />
    </>
  );
};

export default FindingsPlot;
