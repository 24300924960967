export const errors = {
  TAG_EXISTS: 'This tag already exists',
  ENTITY_NOT_SELECTED: 'Please select an entity',
  OUTPUT_LIMIT_REACHED: 'Output limit reached',
  ENTER_JUSTIFICATION: 'Please enter a justification',
  CHARACTER_LIMIT_REACHED: 'You have reached the maximum allowed character limit for this input',
};

export const errorsCodes = {
  UNAUTHORIZED_CODE: 401,
  SERVER_ERROR: 500,
};

export const USER_AND_BENEFICIARY_OUTPUT_ERRORS = {
  UPDATED_FAILED: 'Failed to update output. Please try again.',
  ADD_FAILED: 'Failed to update output. Please try again.',
  ENTER_TEXT_OR_FILE: 'Please enter text or upload a file',
};
