export const getEntitySelectOptions = (projectEntities, outputData, outputToUpdate) => {
  const tagDisplayNames = [];

  if (outputToUpdate) {
    tagDisplayNames.push({ value: outputToUpdate?.entityId, label: outputToUpdate?.Entity_Name });
  }

  Object.values(projectEntities).forEach((projectEntity) => {
    Object.values(projectEntity).forEach((tags) => {
      tags.forEach((tag) => {
        if (!tagDisplayNames.some((existingTag) => existingTag.label === tag.entity_name)) {
          tagDisplayNames.push({ value: tag.id, label: tag.entity_name });
        }
      });
    });
  });

  const filteredTags = tagDisplayNames.filter(
    (tag) => !outputData.some((entity) => entity.project_entity.id === tag.value),
  );
  if (outputToUpdate) {
    filteredTags.push({ value: outputToUpdate?.entityId, label: outputToUpdate?.Entity_Name });
  }

  return filteredTags;
};

export const generateOutputTableRows = (outputs) => {
  const outputRows = [];
  outputs.forEach((output, index) => {
    outputRows.push({
      key: output.id,
      entityId: output.project_entity.id,
      Entity_Number: `Entity ${index + 1}`,
      Entity_Name: output.project_entity.entity_name,
      Justification: output.justification,
    });
  });

  return outputRows;
};

export const getUserDisplayName = (userData) => {
  const { first_name = '', last_name = '', email = '' } = userData;
  const fullName = [first_name, last_name].filter(Boolean).join(' ');
  return fullName || email;
};
