import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from 'src/redux/actions';
import 'src/pages/home/_home.scss';
import { hooks, labels, routes } from 'src/common';
import { useNavigate } from 'react-router-dom';
import { NavigationFooter } from 'src/components';
import { Empty } from 'antd';
import Button from 'src/components/button';
import { resetBFOutputSlice } from "src/redux/reducers/beneficiariesOutputsSlice";
import { resetBeneficiarySlice } from "src/redux/reducers/beneficiariesSlice";
import { resetInfluenceSlice } from "src/redux/reducers/influencesSlice";
import { resetProblemCanvas } from "src/redux/reducers/problemCanvasSlice";
import { resetSolutionCanvasSlice } from "src/redux/reducers/solutionCanvasSlice";
import { resetSummarySlice } from "src/redux/reducers/summarySlice";
import { resetTypingSlice } from "src/redux/reducers/typingSlice";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {owned: ownedProjects, joined: joinedProjects} = useSelector((state) => state.project);
  const [tab, setTab] = useState(0);
  const [selectedProject, setProject] = useState(null);
  const noOwnedProjects = tab === 0 && ownedProjects.length === 0;
  const noJoinedProjects = tab === 1 && joinedProjects.length === 0;

  const changeTab = (tab) => {
    setTab(tab);
    setProject(null);
  };

  const navigateToProject = (id) => {
    navigate(`${routes.PROJECT}/${id}`);
  };

  useEffect(() => {
    Promise.all([
      dispatch(projectActions.resetProjectSlice()),
      dispatch(resetBFOutputSlice()),
      dispatch(resetBeneficiarySlice()),
      dispatch(resetInfluenceSlice()),
      dispatch(resetProblemCanvas()),
      dispatch(resetSolutionCanvasSlice()),
      dispatch(resetSummarySlice()),
      dispatch(resetTypingSlice())
    ]).then(() => {
      dispatch(projectActions.getOwnedProjects());
      dispatch(projectActions.getJoinedProjects());
    });
  }, [dispatch]);

  hooks.usePageTitle(labels.HOME);

  return (<>
    <div className='home'>
      <div className='homeContainer'>
        <div className='homeContent'>
          <Button className='newBtn' onClick={() => navigateToProject('')}>
            {labels.CREATE_NEW}
          </Button>
          <div className='title'>
            <Button
              type={tab === 0 ? 'primary' : 'default'}
              className={`titleBtn ${tab === 0 && 'active'}`}
              onClick={() => changeTab(0)}
            >
              Owned
            </Button>
            <Button
              type={tab === 1 ? 'primary' : 'default'}
              className={`titleBtn ${tab === 1 && 'active'}`}
              onClick={() => changeTab(1)}
            >
              Joined
            </Button>
          </div>
          <div className='projects'>
            {noOwnedProjects && <Empty style={{marginTop: '1rem'}} description='No owned projects'/>}
            {noJoinedProjects && <Empty style={{marginTop: '1rem'}} description='No joined projects'/>}
            {tab === 0 && !noOwnedProjects && ownedProjects.map((project) => {
              return (<Button
                key={project.id}
                type={selectedProject?.id === project.id ? 'primary' : 'default'}
                onClick={() => setProject(project)}
                className={`projectBtn ${selectedProject?.id === project.id && 'active'}`}
              >
                {project.name}
              </Button>);
            })}
            {tab === 1 && !noJoinedProjects && joinedProjects.map((project) => {
              return (<Button
                key={project.id}
                type={selectedProject?.id === project.id ? 'primary' : 'default'}
                onClick={() => setProject(project)}
                className={`projectBtn ${selectedProject?.id === project.id && 'active'}`}
              >
                {project.name}
              </Button>);
            })}
          </div>
        </div>
      </div>
    </div>
    <NavigationFooter
      showBack={false}
      onNext={() => navigateToProject(selectedProject.id)}
      nextDisabled={!selectedProject}
    />
  </>);
}

export default Home;
