import React, { useEffect, useState } from 'react';
import { Button, Input, message, Modal } from 'antd';
import { send_events, useWebSocketContext } from 'src/sockets';
import { ReadyState } from 'react-use-websocket';
import { BREAKTHROUGH_AND_RISK_OUTPUT_LABEL, labels, USER_AND_BENEFICIARY_OUTPUT_MESSAGES } from 'src/common/constants';

const ItemModal = ({ visible, onClose, outputToUpdate }) => {
  const { sendJsonMessage, readyState } = useWebSocketContext();
  const [challenge, setChallenge] = useState(outputToUpdate?.challenge || '');
  const [opportunity, setOpportunity] = useState(outputToUpdate?.opportunity || '');
  const [errors, setErrors] = useState({
    challenge: false,
    opportunity: false,
  });

  const createProjectInfluence = (values) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.CREATE_BREAKTHROUGH_AND_RISK_OUTPUT,
        data: values,
      });
      setChallenge('');
      setOpportunity('');
      onClose();
    }
  };

  const updateProjectInfluence = (id, values) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.UPDATE_BREAKTHROUGH_AND_RISK_OUTPUT,
        data: {
          id,
          ...values,
        },
      });
      onClose();
    }
  };

  const deleteProjectInfluence = () => {
    if (outputToUpdate && readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.DELETE_BREAKTHROUGH_AND_RISK_OUTPUT,
        data: {
          id: outputToUpdate.id,
        },
      });
      onClose();
    }
  };

  const handleFormSubmit = () => {
    const submitErrors = { challenge: false, opportunity: false };
    if (!challenge) {
      submitErrors.challenge = true;
    }
    if (!opportunity) {
      submitErrors.opportunity = true;
    }
    setErrors(submitErrors);
    const values = {
      challenge,
      opportunity,
    };
    if (!challenge || !opportunity) return;
    if (outputToUpdate) {
      updateProjectInfluence(outputToUpdate.id, values);
    } else {
      createProjectInfluence(values);
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: USER_AND_BENEFICIARY_OUTPUT_MESSAGES.DELETE_CONFIRMATION,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteProjectInfluence();
        message.success(USER_AND_BENEFICIARY_OUTPUT_MESSAGES.OUTPUT_DELETED);
      },
    });
  };

  useEffect(() => {
    setOpportunity(outputToUpdate?.opportunity || '');
    setChallenge(outputToUpdate?.challenge || '');
  }, [outputToUpdate]);

  return (
    <Modal
      visible={visible}
      title={outputToUpdate ? labels.UPDATE : 'Create Output'}
      onCancel={() => {
        setChallenge('');
        setOpportunity('');
        onClose();
      }}
      footer={[
        outputToUpdate && (
          <Button
            key='delete'
            danger
            onClick={handleDelete}
            style={{ float: 'left', marginRight: '8px' }}
          >
            Delete
          </Button>
        ),
        <Button key='cancel' onClick={() => {
          setChallenge('');
          setOpportunity('');
          onClose();
        }}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleFormSubmit}>
          {outputToUpdate ? labels.UPDATE : 'Add Output'}
        </Button>,
      ]}
    >
      <Input.TextArea
        rows={4}
        value={challenge}
        status={errors.challenge && 'error'}
        onChange={(e) => setChallenge(e.target.value)}
        placeholder={BREAKTHROUGH_AND_RISK_OUTPUT_LABEL.ENTER_CHALLENGE_PLACEHOLDER}
        style={{ marginBottom: '10px' }}
      />

      <Input.TextArea
        rows={4}
        value={opportunity}
        status={errors.opportunity && 'error'}
        onChange={(e) => setOpportunity(e.target.value)}
        placeholder={BREAKTHROUGH_AND_RISK_OUTPUT_LABEL.ENTER_OPPORTUNITY_PLACEHOLDER}
      />
    </Modal>
  );
};

export default ItemModal;
