import { apiUtils, endpoints } from 'src/api';

export const api = {
  login: async (data) => {
    return await apiUtils.post(endpoints.LOGIN, data, false);
  },
  getOwnedProjects: async () => {
    return await apiUtils.get(endpoints.PROJECTS);
  },
  getJoinedProjects: async () => {
    return await apiUtils.get(endpoints.JOINED_PROJECTS);
  },
  getProject: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_DETAIL(id));
  },
  getUsers: async () => {
    return await apiUtils.get(endpoints.USERS);
  },
  createProject: async (data) => {
    return await apiUtils.post(endpoints.PROJECTS, data);
  },
  updateProject: async ({ id, data }) => {
    return await apiUtils.patch(endpoints.PROJECT_DETAIL(id), data);
  },
  getOpportunities: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_OPPORTUNITY(id));
  },
  getSelectedOpportunities: async (id) => {
    return await apiUtils.get(endpoints.SELECTED_OPPORTUNITY(id));
  },
  getCriteria: async () => {
    return await apiUtils.get(endpoints.CRITERIA);
  },
  getProjectBeneficiaries: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_BENEFICIARIES(id));
  },
  getProjectInfluences: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_INFLUENCES(id));
  },
  getProjectBeneficiariesOutputs: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_BENEFICIARIES_OUTPUTS(id));
  },
  getProjectBreakthoughsRisksOutputs: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_BREAKTHROUGHS_RISKS_OUTPUTS(id));
  },
  addProjectBeneficiriesOutputs: async (id, data) => {
    return await apiUtils.post(endpoints.PROJECT_BENEFICIARIES_OUTPUTS(id), data);
  },
  updateProjectBeneficiriesOutputs: async (id, outputId, data) => {
    return await apiUtils.patch(`${endpoints.PROJECT_BENEFICIARIES_OUTPUTS(id)}${outputId}/`, data);
  },
  deleteProjectBeneficiariesOutputs: async (id, outputId) => {
    return await apiUtils.delete(endpoints.DELETE_BENEFICIARIES_OUTPUTS(id, outputId));
  },
  getBeneficiariesList: async (searchText, project, beneficiaryType) => {
    return await apiUtils.get(endpoints.BENEFICIARIES_LIST, {
      search: searchText,
      project,
      bf_type: beneficiaryType,
    });
  },
  getProjectEntities: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_ENTITIES(id));
  },
  getPeopleOrgOutputs: async (id) => {
    return await apiUtils.get(endpoints.PEOPLE_AND_ORG_OUTPUTS(id));
  },
  getSuggestedEntities: async (search, project = null, projectEntityType = null, psId = null) => {
    const params = {
      search,
      ...(project !== null && { project }),
      ...(projectEntityType !== null && { pe_type: projectEntityType }),
      ...(psId !== null && { ps_id: psId }),
    };
    return await apiUtils.get(endpoints.ENTITIES, params);
  },
  getSuggestedTechnologies: async (search, project = null, psId) => {
    const params = {
      search,
      ...(project !== null && { project }),
      ...(psId !== null && { ps_id: psId }),
    };
    return await apiUtils.get(endpoints.TECHNOLOGY_SUGGESTIONS, params);
  },
  getProjectSubProblems: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_SUB_PROBLEMS(id));
  },
  getProjectTechnologies: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_TECHNOLOGIES(id));
  },
  getSolutions: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_SOLUTIONS(id));
  },
  getPotentialSolutions: async (id) => {
    return await apiUtils.get(endpoints.POTENTIAL_SOLUTIONS(id));
  },
  getPotentialSolutionFindingPlot: async (id) => {
    return await apiUtils.get(endpoints.POTENTIAL_SOLUTION_FINDING_PLOT(id));
  },
  getProjectFactors: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_FACTORS(id));
  },
  getProjectSummary: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_SUMMARY(id));
  },
  getSolutionSuggestions: async (id, search) => {
    return await apiUtils.get(endpoints.PROJECT_SOLUTION_SUGGESTIONS(id), { search });
  },
  getFactorSuggestions: async (search, project) => {
    return await apiUtils.get(endpoints.PROJECT_FACTOR_SUGGESTIONS, { search, project });
  },
  getProjectLearnings: async (id) => {
    return await apiUtils.get(endpoints.PROJECT_LEARNINGS(id));
  },
};
