import React, { useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
import 'src/components/numberInput/numberInput.css';

let inputId = '';

const NumberInput = ({ initialValue, onUpdate, customKey, ...rest }) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  const handleNumberChange = (newValue) => {
    if (newValue === null || Number.isInteger(newValue)) {
      setValue(newValue);
    }
  };

  const saveData = () => {
    onUpdate(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (inputId === customKey) {
      inputRef.current.focus();
    }
  }, [inputId]);

  const handleFocus = (e) => {
    inputId = customKey;
  };

  return (
    <InputNumber
      key={customKey}
      value={value}
      ref={inputRef}
      onFocus={handleFocus}
      onChange={handleNumberChange}
      onBlur={(e) => {
        saveData(e);
        inputId = '';
      }}
      onPressEnter={saveData}
      min={1}
      max={10}
      size={'small'}
      controls={false}
      className='custom-input-number'
      onKeyDown={(event) => {
        if (!(/[0-9]/.test(event.key)) && event.key !== 'Backspace') {
          event.preventDefault();
        }
      }}
      {...rest}
    />
  );
};

export default NumberInput;
