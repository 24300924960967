import React, { useEffect, useState } from 'react';
import { Divider, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { keys } from 'lodash';

import { send_events } from 'src/sockets';
import { hooks, labels, routes } from 'src/common';
import { api } from 'src/api';
import { NavigationFooter, TagInput } from 'src/components';
import { projectActions } from 'src/redux/actions';
import Button from 'src/components/button';
import Typography from 'src/components/typography/typography';
import { fetchBeneficiaries, fetchProjectLearning } from 'src/redux/reducers/beneficiariesSlice';
import { fetchOutputs } from 'src/redux/reducers/beneficiariesOutputsSlice';
import BeneficiariesOutputsModal from 'src/pages/usersAndBeneficiaries/outputsModal';
import 'src/pages/usersAndBeneficiaries/index.scss';
import { materialColumns, QUESTIONS, UserTypes } from 'src/pages/usersAndBeneficiaries/constants';
import { generateParticipantsData, projectLearningsData } from './helpers';
import { useSendJsonMessage } from 'src/sockets/useSendJsonMessage';
import { PAGE_NAMES } from 'src/common/constants';
import { selectTypingDataByPage } from 'src/redux/reducers/typingSlice';
import OutputCard from './OutputCard';

const UsersAndBeneficiaries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { sendJsonMessage } = useSendJsonMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [outputToUpdate, setOutputToUpdate] = useState(null);
  const typingUsers = useSelector(selectTypingDataByPage(PAGE_NAMES.USER_AND_BENEFICIARY));
  const {
    learnings,
    beneficiaries,
    loading: beneficiariesLoading,
  } = useSelector((state) => state.beneficiaries);
  const project = useSelector((state) => state.project.selected);
  const { outputs, loading: outputLoading } = useSelector((state) => state.beneficiariesOutputs);
  const userObject = JSON.parse(localStorage.getItem('user'));
  const userId = userObject?.id;

  const deleteProjectBeneficiary = (id) =>
    sendJsonMessage({
      type: send_events.DELETE_PROJECT_BENEFICIARY,
      data: {
        id,
      },
    });

  const deleteProjectLearning = (id) =>
    sendJsonMessage({
      type: send_events.DELETE_PROJECT_LEARNING,
      data: {
        id,
      },
    });

  const createProjectBeneficiary = (value, bfType) =>
    sendJsonMessage({
      type: send_events.CREATE_PROJECT_BENEFICIARY,
      data: {
        beneficiary: {
          name: value,
        },
        bf_type: bfType,
      },
    });

  const createProjectLearning = (value) =>
    sendJsonMessage({
      type: send_events.CREATE_PROJECT_LEARNING,
      data: {
        name: value,
      },
    });

  const handleSearch = async (value, projectId, beneficiaryType) => {
    const suggestions = await api.getBeneficiariesList(value, projectId, beneficiaryType);
    return suggestions.results.map((item) => ({
      label: item.name,
      value: item.name,
    }));
  };

  const handleAddButtonClick = () => {
    setIsModalVisible(true);
    setOutputToUpdate(null);
  };

  const handleEditButtonClick = (output) => {
    setIsModalVisible(true);
    setOutputToUpdate(output);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setOutputToUpdate(null);
  };

  const getLabels = (tags) => {
    return tags.map((tag) => ({ name: tag.beneficiary_name, id: tag.id }));
  };

  useEffect(() => {
    dispatch(fetchOutputs(id));
    dispatch(fetchBeneficiaries(id));
    dispatch(fetchProjectLearning(id));
    dispatch(projectActions.getProject(id));
  }, [id]);

  hooks.usePageTitle(labels.USER_AND_BENEFICIARY);
  hooks.useScrollToTop();

  return (
    <>
      <div className='usersAndBeneficiariesContainerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider />
        <Typography tag='p' fontSize='30px' fontWeight={500} blue>
          RESOURCE MAP | Users & Beneficiaries
        </Typography>
        <Typography fontSize='14px' fontStyle='italic'>
          Fill out this INPUT section, concentrating on looser brainstorming and discussion. Place
          every idea here, you will clean it up later.
        </Typography>

        {keys(UserTypes).map((key) => (
          <div key={key} className='questionContainer'>
            <Typography fontSize='18px' bold tag='p' blue>
              {QUESTIONS[key]}
            </Typography>
            <Table
              showHeader={false}
              columns={materialColumns}
              dataSource={generateParticipantsData(
                project ? project.userMap : {},
                UserTypes[key],
                userId,
                beneficiaries,
                deleteProjectBeneficiary,
                typingUsers,
              )}
              pagination={false}
              components={{
                body: {
                  // eslint-disable-next-line no-unused-vars
                  cell: ({ onMouseEnter, onMouseLeave, ...restProps }) => <td {...restProps} />,
                },
              }}
            />
            <div className='inputContainer'>
              <TagInput
                tags={getLabels(beneficiaries?.[UserTypes[key]]?.[userObject?.id] || [])}
                isViewOnly={false}
                typingEventData={{ bfType: UserTypes[key], page: PAGE_NAMES.USER_AND_BENEFICIARY }}
                onAdd={(data) => createProjectBeneficiary(data, UserTypes[key])}
                onRemove={(id) => deleteProjectBeneficiary(id)}
                onSearch={(value) => handleSearch(value, id, UserTypes[key])}
              />
            </div>
          </div>
        ))}
        <div className='questionContainer'>
          <Typography fontSize='18px' bold tag='p' blue>
            If possible, please speak with experts to get a more clear understanding about the
            opportunity and place learnings here.
          </Typography>
          <Table
            showHeader={false}
            columns={materialColumns}
            loading={beneficiariesLoading}
            dataSource={projectLearningsData(
              project ? project.userMap : {},
              learnings,
              3,
              userId,
              deleteProjectBeneficiary,
              typingUsers,
            )}
            pagination={false}
            components={{
              body: {
                // eslint-disable-next-line no-unused-vars
                cell: ({ onMouseEnter, onMouseLeave, ...restProps }) => <td {...restProps} />,
              },
            }}
          />
          <div className='inputContainer'>
            <TagInput
              isViewOnly={false}
              tags={learnings?.[userId]}
              typingEventData={{ bfType: 3, page: PAGE_NAMES.USER_AND_BENEFICIARY }}
              onAdd={(data) => createProjectLearning(data)}
              onRemove={(id) => deleteProjectLearning(id)}
              onSearch={() => {}}
            />
          </div>
        </div>
        <Typography fontSize='14px' fontStyle='italic'>
          PS: Please feel free to adjust big opportunity if analysis showed that you need to shift
          the focus.
        </Typography>
        <BeneficiariesOutputsModal
          project_id={id}
          visible={isModalVisible}
          onClose={handleModalClose}
          outputToUpdate={outputToUpdate}
          loading={outputLoading}
        />
        <Typography tag='h2' fontSize='24px' bold blue>
          OUTPUT
        </Typography>
        <Typography grey fontStyle='italic' tag='p'>
          To attach file or provide link, click on the {`'Add Output'`} button below.
        </Typography>
        <div className='unbOutput'>
          {outputs.length === 0 ? (
            <div className='addOutputButtonWrapper'>
              <Button onClick={handleAddButtonClick}>Add Output</Button>
            </div>
          ) : (
            <OutputCard data={outputs[0]} onClick={() => handleEditButtonClick(outputs[0])} />
          )}
        </div>
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${id}/people-and-organizations`)}
        onNext={() => navigate(`${routes.PROJECT}/${id}/breakthroughs-risks`)}
      />
    </>
  );
};

export default UsersAndBeneficiaries;
