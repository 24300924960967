import React, { useEffect, useRef, useState } from 'react';
import { message, Select } from 'antd';
import { errors } from 'src/common/errors';
import 'src/components/TagInput/index.css';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import useSendTypingEvents from 'src/common/useSendTypingEvents';
import Typography from '../typography/typography';
import { TagRender } from 'src/components';
import { globals } from 'src/common';

let inputId = '';

const TagInput = ({
                    id,
                    accessKey = 'name',
                    tags = [],
                    isViewOnly = true,
                    onAdd,
                    onRemove,
                    selectHeight = 'unset',
                    onSearch,
                    typingEventData = {},
                    isTyping = false,
                    customKey,
                  }) => {
  const [options, setOptions] = useState(null);
  const { triggerStartTyping, triggerStopTyping } = useSendTypingEvents();

  message.config({
    maxCount: 1,
  });

  const handleInputConfirm = (tagValue) => {
    triggerStopTyping(typingEventData);
    if (tags.some((tag) => tag[accessKey] === tagValue)) {
      message.info(errors.TAG_EXISTS);
      return;
    }
    if (tagValue.length <= globals.MAX_TAG_LENGTH) {
      onAdd(tagValue);
    }
  };

  const handleTagClose = (tagId) => {
    onRemove(tagId);
  };

  const checkLength = (event) => {
    if (event.target.value.length >= globals.MAX_TAG_LENGTH && event.key !== 'Backspace') {
      event.preventDefault();
      message.error(errors.CHARACTER_LIMIT_REACHED);
    }
  };

  const debouncedGetOptions = debounce(async (event) => {
    const optionsData = await onSearch(event);
    setOptions(optionsData);
  }, 300);

  const handleFocus = (e) => {
    inputId = customKey;
  };

  const labels = tags.map((tag) => ({ label: tag[accessKey], value: tag.id }));
  return (
    <>
      <Select
        id={id}
        key={customKey}
        autoFocus={customKey===inputId}
        mode='tags'
        tagRender={TagRender}
        className='select'
        value={labels}
        onKeyDown={(e) => {
          triggerStartTyping(typingEventData);
          checkLength(e);
        }}
        style={{ width: '100%' }}
        onSelect={handleInputConfirm}
        onFocus={() => {
          handleFocus()
          triggerStartTyping(typingEventData);
          debouncedGetOptions('');
        }}
        onBlur={() => inputId = ''}
        onDeselect={handleTagClose}
        disabled={isViewOnly}
        options={options}
        placeholder={!isViewOnly && 'Enter your response here...'}
        onSearch={debouncedGetOptions}
      />
      <Typography style={{ visibility: isTyping ? 'visible' : 'hidden' }} fontSize='14px'>
        is typing...
      </Typography>
    </>
  );
};

TagInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  accessKey: PropTypes.string,
  typingEventData: PropTypes.shape({}),
  isViewOnly: PropTypes.bool,
  isTyping: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onSearch: PropTypes.func,
};

export default TagInput;
