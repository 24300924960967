export const routes = {
  HOME: '/',
  PROJECT: '/project',
  PROJECT_DYN: '/project/:id',
  PROJECT_OPPORTUNITIES_DYN: '/project/:id/opportunities',
  OPPORTUNITIES: '/opportunities',
  SELECTED_OPPORTUNITY: '/project/:id/selected-opportunity',
  RESOURCE_MAP_USERS_BENEFICIARIES: '/project/:id/users-beneficiaries',
  RESOURCE_MAP_BREAKTHROUGHS_RISKS: '/project/:id/breakthroughs-risks',
  PEOPLE_AND_ORGANIZATIONS: '/project/:id/people-and-organizations',
  SOLUTION_CANVAS: '/project/:id/solution-canvas',
  PROBLEM_CANVAS: '/project/:id/problem-canvas',
  FINDINGS_PLOT: '/project/:id/findings-plot',
  SUMMARY: '/project/:id/summary',
};

export const getRoutes = (id) => ({
  HOME: '/',
  PROJECT: `/project/${id}`,
  PROJECT_OPPORTUNITIES_DYN: `/project/${id}/opportunities`,
  OPPORTUNITIES: `/project/${id}/opportunities`,
  SELECTED_OPPORTUNITY: `/project/${id}/selected-opportunity`,
  RESOURCE_MAP_USERS_BENEFICIARIES: `/project/${id}/users-beneficiaries`,
  RESOURCE_MAP_BREAKTHROUGHS_RISKS: `/project/${id}/breakthroughs-risks`,
  PEOPLE_AND_ORGANIZATIONS: `/project/${id}/people-and-organizations`,
  SOLUTION_CANVAS: `/project/${id}/solution-canvas`,
  PROBLEM_CANVAS: `/project/${id}/problem-canvas`,
  FINDINGS_PLOT: `/project/${id}/findings-plot`,
  SUMMARY: `/project/${id}/summary`,
});
