import React from 'react';
import TagsContainer from 'src/components/TagsContainer/tagsContainer';
import Typography from 'src/components/typography/typography';

export const generateParticipantsData = (
  participants,
  beneficiaryType,
  userId,
  beneficiaries,
  deleteProjectBeneficiary,
  typingUsers,
) => {
  return Object.entries(participants)
    .filter(([_, participant]) => participant?.id !== userId)
    .map(([_, participant], index) => {
      return {
        key: index,
        participant: (
          <Typography color='#808080' fontSize='12px' fontStyle='italic'>
            {participant?.first_name
              ? `${participant?.first_name} ${participant?.last_name}`
              : participant?.email}
          </Typography>
        ),
        responses: (
          <TagsContainer
            accessKey='beneficiary_name'
            isViewOnly={true}
            isTyping={typingUsers[participant?.id]?.bfType == beneficiaryType}
            tags={beneficiaries?.[beneficiaryType]?.[participant?.id]}
            onRemove={({ id }) => deleteProjectBeneficiary(id)}
          />
        ),
      };
    });
};

export const projectLearningsData = (
  participants,
  learnings,
  beneficiaryType,
  userId,
  deleteProjectBeneficiary,
  typingUsers,
) => {
  return Object.entries(participants)
    .filter(([_, participant]) => participant?.id !== userId)
    .map(([_, participant], index) => {
      return {
        key: index,
        participant: (
          <Typography color='#808080' fontSize='12px' fontStyle='italic'>
            {participant?.first_name
              ? `${participant?.first_name} ${participant?.last_name}`
              : participant?.email}
          </Typography>
        ),
        responses: (
          <TagsContainer
            accessKey='name'
            isViewOnly={true}
            isTyping={typingUsers[participant?.id]?.bfType == beneficiaryType}
            tags={learnings?.[participant?.id]}
            onRemove={({ id }) => deleteProjectBeneficiary(id)}
          />
        ),
      };
    });
};

export const getFileExtension = (filename) => {
  return filename.split('.').pop();
};

export const getFileName = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export const isImage = (filename) => {
  const ext = getFileExtension(filename).toLowerCase();
  return ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'gif';
};
