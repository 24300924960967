import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';
import Typography from 'src/components/typography/typography';

const CustomButton = ({ children, onClick, type = 'primary', ...rest }) => {
  let buttonStyle = {
    display: 'flex',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (type === 'primary') {
    buttonStyle = {
      ...buttonStyle,
      borderRadius: '50px',
      background: 'var(--Blue-Light, #EAEBF0)',
    };
  }

  return (
    <AntButton style={buttonStyle} onClick={onClick} {...rest}>
      <Typography style={{ cursor: 'pointer' }} blue>{children}</Typography>
    </AntButton>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomButton;
