import 'src/pages/solutionCanvas/_solutionCanvas.scss';
import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const headerTooltip = (title, toolTip) => {
  return (
    <div className='toolTip'>
      <span style={{ marginRight: '10px' }}>{title}</span>
      <Tooltip title={toolTip}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>);
};

export const primaryRankingColumns = [
  {
    title: '',
    dataIndex: 'Solution',
    key: 'Solution',
    className: 'column-max-width first-column white-column-header',
    width: '20%',
  },
  {
    title: headerTooltip('Cost', '1: very cost efficient, 10: very expensive'),
    dataIndex: 'Cost',
    key: '0',
    className: 'column-max-width yellow-column-header',
    width: '10%',
  },
  {
    title: headerTooltip('Time', '1: very fast, 10: very slow'),
    dataIndex: 'Time',
    key: '1',
    className: 'column-max-width yellow-column-header',
    width: '10%',
  },
  {
    title: headerTooltip('Impact', '1: very low, 10: very high'),
    dataIndex: 'Impact',
    key: '2',
    className: 'column-max-width green-column-header',
    width: '10%',
  },
  {
    title: 'List types of ppl/groups who benefit',
    dataIndex: 'People',
    key: '3',
    className: 'column-max-width gray-column-header',
    width: '25%',
  },
  {
    title: 'List of potential technologies',
    dataIndex: 'Technologies',
    key: '4',
    className: 'column-max-width gray-column-header',
    width: '25%',
  },
];

export const secondaryRankingColumns = [
  {
    title: '',
    dataIndex: 'Solution',
    key: 'Solution',
    className: 'column-max-width first-column white-column-header',
    fixed: 'left',
  },
  {
    title: headerTooltip('Competition', '1: lot of competition, 10: no competition'),
    dataIndex: 'Competition',
    key: '5',
    className: 'column-max-width lightgray-column-header',
  },
  {
    title: headerTooltip('Skills', '1: requires high level of skill, 10: requires low level of skill'),
    dataIndex: 'Skills',
    key: '6',
    className: 'column-max-width lightgray-column-header',
  },
  {
    title: 'Sum of Constraints',
    dataIndex: 'Sum',
    key: '10',
    className: 'column-max-width lightgray-column-header',
    fixed: 'right',
  },
];

export const labels = {
  CONFIRM_FACTOR_DELETION: 'Do you want to delete this factor?',
  FACTOR_DELETED_SUCCESSFULLY: 'Factor deleted successfully',
  POTENTIAL_SOLUTION_NOT_SELECTED: 'Select a potential solution',
  FACTOR_NOT_SELECTED: 'Create or select a factor',
  FACTOR_CANNOT_BE_EMPTY: 'Factor name cannot be empty',
};
