import React, { useEffect, useState } from 'react';
import Typography from 'src/components/typography/typography';
import 'src/pages/peopleAndOrganizations/_peopleAndOrganizations.scss';
import { NavigationFooter, TagInput } from 'src/components';
import { hooks, labels, local, routes, utils } from 'src/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Table } from 'antd';
import { projectActions } from 'src/redux/actions';
import { send_events, useWebSocketContext } from 'src/sockets';
import { PAGE_NAMES } from 'src/common/constants';
import { ReadyState } from 'react-use-websocket';
import Button from 'src/components/button';
import { outputColumns, pageSections, project_entity_types } from 'src/pages/peopleAndOrganizations/constants';
import { generateOutputTableRows, getUserDisplayName } from 'src/pages/peopleAndOrganizations/helpers';
import { api } from 'src/api';
import { selectTypingDataByPage } from 'src/redux/reducers/typingSlice';
import OutputModal from './OutputModal';

const PeopleAndOrganizations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outputToUpdate, setOutputToUpdate] = useState(null);
  const {
    selected: project,
    projectEntitiesLoading,
    peopleAndOrgOutputsLoading: projectOutputsLoading,
  } = useSelector((state) => state.project);
  const tags = useSelector((state) => state.project.projectEntities);
  const outputs = useSelector((state) => state.project.peopleAndOrgOutputs);
  const typingUsers = useSelector(selectTypingDataByPage(PAGE_NAMES.PEOPLE_AND_ORG));

  const { id } = useParams();
  const loggedUser = local.getUser().id;
  const { sendJsonMessage, readyState } = useWebSocketContext();

  const onNewEntityCreated = (name, pe_type) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.CREATE_PROJECT_ENTITY,
        data: { entity: { name }, pe_type },
      });
    }
  };

  const onEntityDeleted = (id) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.DELETE_PROJECT_ENTITY,
        data: { id },
      });
    }
  };

  const fetchOptionsData = async (event, projectId, peType) => {
    const response = await api.getSuggestedEntities(event, projectId, peType);
    return response['results'].map((option) => ({ key: option['id'], value: option['name'] }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setOutputToUpdate(null);
  };

  const getLabels = (tags) => {
    return tags.map((tag) => ({ name: tag.entity_name, id: tag.id }));
  };

  const generateParticipantTagRows = (projectEntities) => {
    if (!project) {
      return [];
    }

    return Object.entries(project.userMap).map(([_, participant], rowIndex) => {
      const isSameUser = participant.id === loggedUser;
      const participantProjectEntities = projectEntities[participant.id] || {};
      const entityTags = {};
      Object.entries(project_entity_types).forEach(([entity, key]) => {
        entityTags[key] = (
          <TagInput
            id={`${key}-${rowIndex}-People-Organizations`}
            tags={getLabels(participantProjectEntities[entity] || [])}
            isViewOnly={!isSameUser}
            selectHeight='70px'
            typingEventData={isSameUser ? { page: PAGE_NAMES.PEOPLE_AND_ORG, entity } : {}}
            isTyping={isSameUser ? false : typingUsers[participant?.id]?.entity == entity}
            onAdd={(name) => onNewEntityCreated(name, entity)}
            onRemove={onEntityDeleted}
            onSearch={(event) => fetchOptionsData(event, id, entity)}
          />
        );
      });

      return {
        key: participant.id,
        isSameUser,
        Participant: `${getUserDisplayName(participant)} ${isSameUser ? '(you)' : ''}`,
        ...entityTags,
      };
    });
  };

  const handleOutputEdit = (row) => {
    setIsModalOpen(true);
    setOutputToUpdate(row);
  };

  useEffect(() => {
    if (!project) {
      dispatch(projectActions.getProject(id));
    }
  }, [dispatch, id, project]);

  useEffect(() => {
    dispatch(projectActions.getProjectEntities(id));
  }, []);

  useEffect(() => {
    dispatch(projectActions.getPeopleOrgOutputs(id));
  }, []);

  const rowsData = generateParticipantTagRows(tags);
  const sections = pageSections(rowsData);

  hooks.usePageTitle(labels.PEOPLE_AND_ORG);
  hooks.useScrollToTop();

  return (
    <>
      <div className='peopleAndOrgsContainerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider />

        <Typography blue bold fontSize='24px' tag='h1'>
          RESOURCE MAP | People & Organizations
        </Typography>
        <Typography fontSize='14px' fontStyle='italic'>
          With your team, get a better idea about the scale of the big opportunity by accumulating
          as much information as possible. This is a very important part of the SPOT process;
          becoming fully immersed in the opportunity ecosystem to thoroughly fill out every part of
          the resource map will improve the process entirely.
        </Typography>
        {sections.map((section, index) => (
          <div key={section.id} className='questionContainer'>
            <Typography blue bold fontSize='16px' tag='h1'>
              {section.heading}
            </Typography>
            {index === 0 && (
              <div style={{ paddingBottom: '20px' }}>
                <Typography fontSize='14px' fontStyle='italic'>
                  If time allows, fill out other entities and organizations relevant to the big
                  opportunity.
                </Typography>
              </div>
            )}
            <Table
              columns={section.tableColumns.map((column) => ({
                ...column,
                onCell: (record, rowIndex) => {
                  return utils.focusCellElement(`${column?.dataIndex}-${rowIndex}-People-Organizations`);
                },
              }))}
              dataSource={section.tableData}
              pagination={false}
              loading={projectEntitiesLoading}
              rowClassName={(record) => record.isSameUser && 'own-row'}
            />
          </div>
        ))}
        <Typography fontSize='14px' fontStyle='italic'>
          PS: Please feel free to adjust big opportunity if analysis showed that you need to shift the focus.
        </Typography>
        <Typography blue bold fontSize='24px' tag='h1' style={{ marginTop: '3rem' }}>
          OUTPUT
        </Typography>
        <Typography grey fontStyle='italic' tag='p'>
          Fill out this OUTPUT section, selecting the TOP 10 organizations, people, and materials
          from your list above, related to big opportunity.
        </Typography>
        <Table
          columns={outputColumns}
          dataSource={generateOutputTableRows(outputs)}
          className='output-table'
          pagination={false}
          loading={projectOutputsLoading}
          onRow={(record) => ({ onClick: () => handleOutputEdit(record) })}
          components={{
            // eslint-disable-next-line no-unused-vars
            body: { cell: ({ onMouseEnter, onMouseLeave, ...restProps }) => <td {...restProps} /> },
          }}
        />

        <div className='add-entity-button'>
          <Button onClick={showModal}>Add Entity</Button>
        </div>

        <OutputModal
          isModalOpen={isModalOpen}
          onClose={handleClose}
          tags={tags}
          outputs={outputs}
          outputToUpdate={outputToUpdate}
        />
      </div>

      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}/selected-opportunity`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/users-beneficiaries`)}
      />
    </>
  );
};

export default PeopleAndOrganizations;
