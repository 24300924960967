import React from 'react';
import PropTypes from 'prop-types';

const Typography = ({
  tag: Tag = 'span',
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  blue,
  black,
  grey,
  bold,
  children,
  className,
  style = {},
}) => {
  const textColor = blue ? '#212D6D' : black ? '#000' : grey ? '#454545' : color || '#454545';
  const textStyle = {
    cursor: 'default',
    color: textColor,
    fontFamily: fontFamily || 'Roboto',
    fontSize: fontSize || '16px',
    fontStyle: fontStyle || 'normal',
    fontWeight: bold ? 600 : fontWeight || 400,
    lineHeight: lineHeight || 'normal',
  };

  return (
    <Tag className={className} style={{ ...textStyle, ...style }}>
      {children}
    </Tag>
  );
};

Typography.propTypes = {
  tag: PropTypes.oneOf(['p', 'h1', 'h2', 'h3', 'span']),
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontStyle: PropTypes.string,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.string,
  blue: PropTypes.bool,
  black: PropTypes.bool,
  grey: PropTypes.bool,
  bold: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Typography;
