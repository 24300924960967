import { ReadyState } from 'react-use-websocket';
import { useWebSocketContext } from '.';

export const useSendJsonMessage = () => {
  const { sendJsonMessage, readyState } = useWebSocketContext();

  const sendMessage = (message) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage(message);
    } else {
      console.error('WebSocket connection is not open.');
    }
  };

  return { sendJsonMessage: sendMessage };
};
