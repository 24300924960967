import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const CustomTag = ({ label, closable, onClose }) => {
  const tagStyle = {
    wordBreak: 'break-all',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    border: '1px solid #e8e8e8',
    margin: '5px',
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <p style={tagStyle}>
      {label}
      {closable && (
        <CloseOutlined
          onClick={handleClose}
          style={{ marginLeft: '5px', cursor: 'pointer', fontSize: '10px', paddingTop: '1px' }}
        />
      )}
    </p>
  );
};

export default CustomTag;
