import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReadyState } from 'react-use-websocket';
import propTypes from 'prop-types';

import { send_events, useWebSocketContext } from 'src/sockets';
import { addOutput, updateOutput } from 'src/redux/reducers/beneficiariesOutputsSlice';
import { USER_AND_BENEFICIARY_OUTPUT_ERRORS } from 'src/common/errors';
import { labels, USER_AND_BENEFICIARY_OUTPUT_MESSAGES } from 'src/common/constants';

const BeneficiariesOutputsModal = ({ visible, onClose, outputToUpdate, loading }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const { id } = useParams();
  const { sendJsonMessage, readyState } = useWebSocketContext();

  const deleteUserAndBeneficiaryOutput = () => {
    if (outputToUpdate && readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.DELETE_USER_AND_BENEFICIARY_OUTPUT,
        data: {
          id: outputToUpdate.id,
        },
      });
      onClose();
    }
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      const { link } = values;
      if (!link && fileList.length === 0) {
        message.error(USER_AND_BENEFICIARY_OUTPUT_ERRORS.ENTER_TEXT_OR_FILE);
        return;
      }
      const formData = new FormData();
      formData.append('link', link || '');

      if (fileList.length > 0 && fileList[0].uid !== '-1') {
        formData.append('attachment', fileList[0].originFileObj, fileList[0].name);
      } else if (fileList.length === 0) {
        formData.append('attachment', '');
      }

      if (outputToUpdate) {
        dispatch(updateOutput({ projectId: id, outputId: outputToUpdate.id, newData: formData }))
          .then(() => {
            form.resetFields();
            setFileList([]);
            onClose();
          })
          .catch(() => {
            message.error(USER_AND_BENEFICIARY_OUTPUT_ERRORS.UPDATED_FAILED);
          });
      } else {
        dispatch(addOutput({ id, data: formData }))
          .then(() => {
            form.resetFields();
            setFileList([]);
            onClose();
          })
          .catch(() => {
            message.error(USER_AND_BENEFICIARY_OUTPUT_ERRORS.ADD_FAILED);
          });
      }
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: USER_AND_BENEFICIARY_OUTPUT_MESSAGES.DELETE_CONFIRMATION,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteUserAndBeneficiaryOutput();
        message.success(USER_AND_BENEFICIARY_OUTPUT_MESSAGES.OUTPUT_DELETED);
      },
    });
  };

  const handleFileUpload = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  useEffect(() => {
    if (!outputToUpdate) {
      form.resetFields();
      setFileList([]);
      return;
    }
    form.setFieldsValue({ link: outputToUpdate.link || '' });
    outputToUpdate.attachment_link && setFileList([
      {
        uid: '-1',
        name: outputToUpdate.attachment_link.split('/').pop(),
        status: 'done',
        url: outputToUpdate.attachment_link,
      },
    ]);
  }, [outputToUpdate, form]);

  return (
    <Modal
      visible={visible}
      title={outputToUpdate ? 'Edit Output' : 'Add Output'}
      onCancel={onClose}
      footer={[
        outputToUpdate && (
          <Button key='delete' danger onClick={handleDelete} style={{ float: 'left', marginRight: '8px' }}
                  disabled={loading}>
            Delete
          </Button>
        ),
        <Button key='cancel' onClick={onClose} disabled={loading}>
          Cancel
        </Button>,
        <Button key='save' type='primary' onClick={handleSave} loading={loading}>
          {outputToUpdate ? labels.UPDATE : 'Save'}
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical'>
        <Form.Item
          name='link'
          rules={[{ required: fileList.length === 0, message: 'Please enter Output URL' }]}
        >
          <Input placeholder='Enter Output URL' />
        </Form.Item>
        <Form.Item>
          <Upload
            fileList={fileList}
            onChange={handleFileUpload}
            beforeUpload={() => false}
            maxCount={1}
          >
            <Button disabled={loading}>Select File</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

BeneficiariesOutputsModal.propTypes = {
  visible: propTypes.bool.isRequired,
  onClose: propTypes.func,
  outputToUpdate: propTypes.shape({
    id: propTypes.number,
    link: propTypes.string,
    attachment_link: propTypes.string,
  }),
};

export default BeneficiariesOutputsModal;
