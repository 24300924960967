import React from 'react';
import 'src/components/user/_user.scss';
import { RxCross1 } from 'react-icons/rx';

function User({ user, onRemove = null }) {
  function formatName(data) {
    if (data?.first_name && data?.last_name) {
      return `${data.first_name} ${data.last_name}`;
    } else {
      return data?.representation;
    }
  }
  return (
    <div className='user'>
      <span>{formatName(user)}</span>
      {onRemove && (
        <button onClick={() => onRemove(user?.id)}>
          <RxCross1 />
        </button>
      )}
    </div>
  );
}

export default User;
