import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';

const INITIAL_STATE = {
  outputs: [],
  loading: false,
  status: 'idle',
  error: null,
};

export const fetchOutputs = createAsyncThunk('outputs/fetchOutputs', async (project_id) => {
  try {
    const response = await api.getProjectBeneficiariesOutputs(project_id);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const addOutput = createAsyncThunk('outputs/addOutput', async ({id, data}) => {
  try {
    const response = await api.addProjectBeneficiriesOutputs(id, data);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const updateOutput = createAsyncThunk(
  'outputs/updateOutput',
  async ({projectId, outputId, newData}) => {
    try {
      const response = await api.updateProjectBeneficiriesOutputs(projectId, outputId, newData);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
);

const outputsSlice = createSlice({
  name: 'outputs',
  initialState: INITIAL_STATE,
  reducers: {
    resetBFOutputSlice: () => INITIAL_STATE,
    setUsersAndBeneficiaryOutput(state, action) {
      state.outputs = [action.payload];
    },
    deleteUsersAndBeneficiaryOutput(state) {
      state.outputs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOutputs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOutputs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.outputs = action.payload;
      })
      .addCase(fetchOutputs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addOutput.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addOutput.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addOutput.fulfilled, (state, action) => {
        state.loading = false;
        state.outputs = [action.payload];
      })
      .addCase(updateOutput.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOutput.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateOutput.fulfilled, (state, action) => {
        state.loading = false;
        state.outputs = [action.payload];
      });
  },
});
export const {resetBFOutputSlice, setUsersAndBeneficiaryOutput, deleteUsersAndBeneficiaryOutput} =
  outputsSlice.actions;
export default outputsSlice.reducer;
