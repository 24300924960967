import { API_BASE_URL } from 'src/config';

const V1 = `${API_BASE_URL}/api/v1`;
const USERS = `${V1}/users`;
const CORE = `${V1}/core`;

export const endpoints = {
  LOGIN: `${USERS}/login/`,
  REFRESH_TOKEN: `${USERS}/refresh-token/`,
  PROJECTS: `${CORE}/projects/`,
  JOINED_PROJECTS: `${CORE}/projects/joined/`,
  PROJECT_DETAIL: (id) => `${CORE}/projects/${id}/`,
  USERS: `${USERS}/users/`,
  PROJECT_OPPORTUNITY: (id) => `${CORE}/projects/${id}/opportunities/`,
  SELECTED_OPPORTUNITY: (id) => `${CORE}/projects/${id}/selected-opportunities/`,
  CRITERIA: `${CORE}/criteria/`,
  PROJECT_BENEFICIARIES: (id) => `${CORE}/projects/${id}/project-beneficiaries/`,
  PROJECT_INFLUENCES: (id) => `${CORE}/projects/${id}/project-influences/`,
  PROJECT_BENEFICIARIES_OUTPUTS: (id) => `${CORE}/projects/${id}/user-and-beneficiary-outputs/`,
  PROJECT_BREAKTHROUGHS_RISKS_OUTPUTS: (id) =>
    `${CORE}/projects/${id}/breakthrough-and-risk-outputs/`,
  DELETE_BENEFICIARIES_OUTPUTS: (id, outputId) =>
    `${CORE}/projects/${id}/user-and-beneficiary-outputs/${outputId}/`,
  BENEFICIARIES_LIST: `${CORE}/beneficiaries`,
  PROJECT_ENTITIES: (id) => `${CORE}/projects/${id}/project-entities/`,
  PEOPLE_AND_ORG_OUTPUTS: (id) => `${CORE}/projects/${id}/people-and-org-outputs/`,
  ENTITIES: `${CORE}/entities/`,
  PROJECT_SUB_PROBLEMS: (id) => `${CORE}/projects/${id}/project-sub-problems/`,
  PROJECT_TECHNOLOGIES: (id) => `${CORE}/projects/${id}/project-technologies/`,
  PROJECT_SOLUTIONS: (id) => `${CORE}/projects/${id}/project-solutions/`,
  POTENTIAL_SOLUTIONS: (id) => `${CORE}/projects/${id}/potential-solutions/`,
  POTENTIAL_SOLUTION_FINDING_PLOT: (id) => `${CORE}/projects/${id}/potential-solutions/finding-plot/`,
  PROJECT_FACTORS: (id) => `${CORE}/projects/${id}/project-factors/`,
  PROJECT_SUMMARY: (id) => `${CORE}/projects/${id}/summary/`,
  PROJECT_SOLUTION_SUGGESTIONS: (id) => `${CORE}/projects/${id}/project-solutions/suggestions`,
  PROJECT_FACTOR_SUGGESTIONS: `${CORE}/factors`,
  TECHNOLOGY_SUGGESTIONS: `${CORE}/technologies`,
  PROJECT_LEARNINGS: (id) => `${CORE}/projects/${id}/project-learnings/`,
};
