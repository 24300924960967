import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Typography, Row, Col } from 'antd';
import { FileImageOutlined, FileOutlined, DownloadOutlined } from '@ant-design/icons';
import { getFileName, isImage } from './helpers';
import './output-card.css';

const { Link } = Typography;

const OutputCard = ({ data, onClick }) => {
  const { attachment_link: attachmentLink, link } = data;
  return (
    <div className='card-container'>
      <Card onClick={onClick} className='card'>
        {attachmentLink ? (
          <div className='card-attachment-container'>
            {isImage(attachmentLink) ? (
              <img src={attachmentLink} alt={getFileName(attachmentLink)} className='card-image' />
            ) : (
              <FileOutlined className='file-icon' />
            )}

            <span>{getFileName(attachmentLink)}</span>

            <div onClick={(e) => e.stopPropagation()}>
              <Button
                key={2}
                type='link'
                icon={<DownloadOutlined />}
                href={attachmentLink}
                target='_blank'
                download
              />
            </div>
          </div>
        ) : (
          <div className='upload-prompt'>
            <FileImageOutlined className='upload-prompt-icon' />
            <p>Click to upload file</p>
          </div>
        )}

        {link && (
          <Row align='middle' justify='center' className='link-row'>
            <Col span={36}>
              <div onClick={(e) => e.stopPropagation()}>
                <Link href={link} target='_blank'>
                  {link}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

OutputCard.propTypes = {
  data: PropTypes.shape({
    attachment_link: PropTypes.string,
    link: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default OutputCard;
