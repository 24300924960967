import React, { useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';
import { MenuOutlined, UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useWebSocketContext } from 'src/sockets';
import { getRoutes } from 'src/common/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { local } from 'src/common';
import 'src/components/navBar/_navBar.scss';
import { useRef } from 'react';
import { ReadyState } from 'react-use-websocket';

const { Header, Sider } = Layout;

const NavigationBar = ({ children, canNavigate, setNextPage }) => {
  const { readyState } = useWebSocketContext();
  const [sideNavVisible, setSideNavVisible] = useState(false);
  const navigate = useNavigate();
  const { selected: project } = useSelector((state) => state.project);
  const projectId = project?.id;
  const routes = getRoutes(projectId);
  const loggedUser = local.getUser();
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);
  const siderRef = useRef();

  let menuItems = [{ key: '1', label: 'Home', route: routes.HOME }];

  if (project?.id) {
    menuItems = [
      ...menuItems,
      { key: '2', label: 'Project', route: routes.PROJECT },
      { key: '3', label: 'Opportunities', route: routes.OPPORTUNITIES },
      { key: '4', label: 'Selected Opportunity', route: routes.SELECTED_OPPORTUNITY },
      { key: '5', label: 'People & Organizations', route: routes.PEOPLE_AND_ORGANIZATIONS },
      {
        key: '6',
        label: 'Users & Beneficiaries',
        route: routes.RESOURCE_MAP_USERS_BENEFICIARIES,
      },
      {
        key: '7',
        label: 'Breakthroughs & Risks',
        route: routes.RESOURCE_MAP_BREAKTHROUGHS_RISKS,
      },
      { key: '8', label: 'PROBLEM CANVAS', route: routes.PROBLEM_CANVAS },
      { key: '9', label: 'SOLUTION CANVAS', route: routes.SOLUTION_CANVAS },
      { key: '10', label: 'FINDINGS PLOT', route: routes.FINDINGS_PLOT },
      { key: '11', label: 'Summary of Findings', route: routes.SUMMARY },
    ];
  }

  const fancyFontStyle = {
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    letterSpacing: '1px',
    color: '#333',
    margin: 0,
    display: 'inline',
    paddingLeft: '10px',
    lineHeight: 'normal',
  };

  const handleLogout = () => {
    local.clearLocalStorge();
    window.location.href = '/';
  };

  const handleMenuItemClick = (item) => {
    canNavigate ? navigate(item.route) : setNextPage(item.route);
    setSideNavVisible(!sideNavVisible);
  };

  const isConnectionNotClose =
    readyState === ReadyState.CONNECTING ||
    readyState === ReadyState.OPEN ||
    readyState === ReadyState.UNINSTANTIATED;

  useEffect(() => {
    const updateActiveRoute = () => {
      setActiveRoute(location.pathname);
    };
    updateActiveRoute();
  }, [location]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (siderRef.current && !siderRef.current.contains(event.target)) {
        setSideNavVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [siderRef]);

  useEffect(() => {
    if (canNavigate) {
      setNextPage(null);
    }
  }, [canNavigate]);

  return (
    <Layout className='navBar'>
      <Sider
        className='sideBar'
        trigger={null}
        ref={siderRef}
        collapsible
        collapsed={!sideNavVisible}
        style={{ width: sideNavVisible ? 'unset' : '0' }}
        collapsedWidth={0}
      >
        <Button
          className='collapseBtn'
          icon={<ArrowLeftOutlined />}
          onClick={() => setSideNavVisible(!sideNavVisible)}
        />
        <Menu className='menu' theme='dark' mode='inline'>
          {menuItems.map((item) => (
            <Menu.Item
              className={`menuItem ${activeRoute === item.route && 'active'}`}
              key={item.key}
              onClick={() => handleMenuItemClick(item)}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header className='header'>
          <div className='leftContent'>
            <MenuOutlined onClick={() => setSideNavVisible(!sideNavVisible)} />
            <h1 style={fancyFontStyle}>Spot Probe</h1>
          </div>
          <div className='centerContent'>
            <div style={{ visibility: isConnectionNotClose ? 'hidden' : 'visible' }}>
              <span className='connection'>
                Socket disconnected, please check your internet connection.
              </span>
            </div>
          </div>
          <div>
            <Dropdown
              placement='bottomRight'
              menu={{
                items: [{ label: 'Logout', key: '1' }],
                onClick: handleLogout,
              }}
            >
              <span>
                <Avatar size='large' icon={<UserOutlined />} />{' '}
                <span
                  style={{ marginLeft: 8 }}
                >{`${loggedUser?.first_name} ${loggedUser?.last_name}`}</span>
              </span>
            </Dropdown>
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.node,
  canNavigate: PropTypes.bool,
  setNextPage: PropTypes.func,
};

export default NavigationBar;
