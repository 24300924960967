import { receive_events } from 'src/sockets';
import { projectActions } from 'src/redux/actions';
import {
  deleteBeneficiaries,
  deleteProjectLearning,
  updateBeneficiariesOnCreate,
  updateLearnings,
} from 'src/redux/reducers/beneficiariesSlice';
import {
  addOutput,
  deleteOutput,
  updateInfluencesOnCreate,
  updateOutput,
} from 'src/redux/reducers/influencesSlice';
import {
  deleteUsersAndBeneficiaryOutput,
  setUsersAndBeneficiaryOutput,
} from 'src/redux/reducers/beneficiariesOutputsSlice';
import {
  addNewTechnology,
  deleteSolution,
  deleteSubProblem,
  deleteTechnology,
  handleProjectSolutionCreated,
  updateSolutions,
  updateSubProblemName,
  updateTechnology,
} from 'src/redux/reducers/problemCanvasSlice';
import {
  addEntity,
  addPotentialSolution,
  addProjectFactors,
  addTechnology,
  deleteProjectFactor,
  removeEntity,
  removeTechnology,
  updatePotentialSolution,
  updateProjectFactor,
  updatePsFactor,
} from 'src/redux/reducers/solutionCanvasSlice';
import { startTyping, stopTyping } from 'src/redux/reducers/typingSlice';
import { updateAnswer } from '../redux/reducers/summarySlice';
import { toast } from 'react-toastify';

export const handler = {
  [receive_events.PROJECT_UPDATED]: (dispatch, data) => {
    dispatch(projectActions.updateProjectSignal(data));
  },
  [receive_events.OPPORTUNITY_CREATED]: (dispatch, data) => {
    dispatch(projectActions.addOpportunity(data));
  },
  [receive_events.OPPORTUNITY_UPDATED]: (dispatch, data) => {
    dispatch(projectActions.updateOpportunity(data));
  },
  [receive_events.OPPORTUNITY_CRITERION_UPDATED]: (dispatch, data) => {
    dispatch(projectActions.updateOpportunityCriterion(data));
  },
  [receive_events.BIG_OPPORTUNITY_SELECTED]: (dispatch, data) => {
    dispatch(projectActions.updateBigOpportunity(data));
  },
  [receive_events.PROJECT_BENEFICIARY_CREATED]: (dispatch, data) => {
    dispatch(updateBeneficiariesOnCreate(data));
  },
  [receive_events.PROJECT_BENEFICIARY_DELETED]: (dispatch, data) => {
    dispatch(deleteBeneficiaries(data));
  },
  [receive_events.BREAKTHROUGH_AND_RISK_OUTPUT_DELETED]: (dispatch, data) => {
    dispatch(deleteOutput(data));
  },
  [receive_events.BREAKTHROUGH_AND_RISK_OUTPUT_CREATED]: (dispatch, data) => {
    dispatch(addOutput(data));
  },
  [receive_events.PROJECT_INFLUENCE_SAVED]: (dispatch, data) => {
    dispatch(updateInfluencesOnCreate(data));
  },
  [receive_events.USER_AND_BENEFICIARY_OUTPUT_DELETED]: (dispatch) => {
    dispatch(deleteUsersAndBeneficiaryOutput());
  },
  [receive_events.USER_AND_BENEFICIARY_OUTPUT_CREATED]: (dispatch, data) => {
    dispatch(setUsersAndBeneficiaryOutput(data));
  },
  [receive_events.USER_AND_BENEFICIARY_OUTPUT_UPDATED]: (dispatch, data) => {
    dispatch(setUsersAndBeneficiaryOutput(data));
  },
  [receive_events.PROJECT_ENTITY_CREATED]: (dispatch, data) => {
    dispatch(projectActions.addProjectEntity(data));
  },
  [receive_events.PROJECT_ENTITY_DELETED]: (dispatch, data) => {
    dispatch(projectActions.deleteProjectEntity(data));
  },
  [receive_events.PEOPLE_ORG_OUTPUT_CREATED]: (dispatch, data) => {
    dispatch(projectActions.addOutput(data));
  },
  [receive_events.PEOPLE_ORG_OUTPUT_UPDATED]: (dispatch, data) => {
    dispatch(projectActions.updateOutput(data));
  },
  [receive_events.PEOPLE_ORG_OUTPUT_DELETED]: (dispatch, data) => {
    dispatch(projectActions.deleteOutput(data));
  },
  [receive_events.PROJECT_TECHNOLOGY_CREATED]: (dispatch, data) => {
    dispatch(addNewTechnology(data));
  },
  [receive_events.PROJECT_SUB_PROBLEM_CREATED]: (dispatch, data) => {
    dispatch(updateSolutions(data));
  },
  [receive_events.PROJECT_TECHNOLOGY_UPDATED]: (dispatch, data) => {
    dispatch(updateTechnology(data));
  },
  [receive_events.PROJECT_SUB_PROBLEM_UPDATED]: (dispatch, data) => {
    dispatch(updateSubProblemName(data));
  },
  [receive_events.PROJECT_SOLUTION_CREATED]: (dispatch, data) => {
    dispatch(handleProjectSolutionCreated(data));
  },
  [receive_events.PROJECT_TECHNOLOGY_DELETED]: (dispatch, data) => {
    dispatch(deleteTechnology(data));
  },
  [receive_events.PROJECT_SOLUTION_DELETED]: (dispatch, data) => {
    dispatch(deleteSolution(data));
  },
  [receive_events.POTENTIAL_SOLUTION_CREATED]: (dispatch, data) => {
    dispatch(addPotentialSolution(data));
  },
  [receive_events.PROJECT_FACTOR_CREATED]: (dispatch, data) => {
    dispatch(addProjectFactors(data));
  },
  [receive_events.PROJECT_FACTOR_UPDATED]: (dispatch, data) => {
    dispatch(updateProjectFactor(data));
  },
  [receive_events.PROJECT_FACTOR_DELETED]: (dispatch, data) => {
    dispatch(deleteProjectFactor(data));
  },
  [receive_events.PROJECT_SUB_PROBLEM_DELETED]: (dispatch, data) => {
    dispatch(deleteSubProblem(data));
  },
  [receive_events.POTENTIAL_SOLUTION_UPDATED]: (dispatch, data) => {
    dispatch(updatePotentialSolution(data));
  },
  [receive_events.PS_FACTOR_SAVED]: (dispatch, data) => {
    dispatch(updatePsFactor(data));
  },
  [receive_events.PS_ENTITY_CREATED]: (dispatch, data) => {
    dispatch(addEntity(data));
  },
  [receive_events.PS_ENTITY_DELETED]: (dispatch, data) => {
    dispatch(removeEntity(data));
  },
  [receive_events.PS_TECHNOLOGY_CREATED]: (dispatch, data) => {
    dispatch(addTechnology(data));
  },
  [receive_events.PS_TECHNOLOGY_DELETED]: (dispatch, data) => {
    dispatch(removeTechnology(data));
  },
  [receive_events.TYPING_STARTED]: (dispatch, data) => {
    dispatch(startTyping(data));
  },
  [receive_events.TYPING_STOPPED]: (dispatch, data) => {
    dispatch(stopTyping(data));
  },
  [receive_events.BREAKTHROUGH_AND_RISK_OUTPUT_UPDATED]: (dispatch, data) => {
    dispatch(updateOutput(data));
  },
  [receive_events.SUMMARY_UPDATED]: (dispatch, data) => {
    dispatch(updateAnswer(data));
  },
  [receive_events.PROJECT_LEARNING_CREATED]: (dispatch, data) => {
    dispatch(updateLearnings(data));
  },
  [receive_events.PROJECT_LEARNING_DELETED]: (dispatch, data) => {
    dispatch(deleteProjectLearning(data));
  },
  [receive_events.ERROR]: (dispatch, data) => {
    if (data.error.startsWith('Project') && data.error.endsWith('not found')) return; // suppress these error
    toast.error(data.error || 'Something went wrong!', {
      position: toast.POSITION.TOP_CENTER,
    });
  },
};

export const handleEvent = (dispatch, jsonMessage) => {
  const callback = handler[jsonMessage.type];
  callback && callback(dispatch, jsonMessage.data);
};
