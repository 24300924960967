import { createSlice } from '@reduxjs/toolkit';
import { authActions, userActions } from '../actions';
import { utils } from '../../common';

const INITIAL_STATE = {
  loading: false,
  users: [],
  userMap: {},
};

export const userSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetUserSlice: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(userActions.getUsers.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(userActions.getUsers.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(userActions.getUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.users = payload;
      state.userMap = utils.toArrayMapping(payload);
    });
  },
});

const { actions, reducer } = userSlice;
export default reducer;
