import { errors, errorsCodes } from 'src/common/errors';
import { toast } from 'react-toastify';
import { globals } from './constants';
import { message } from 'antd';

export const utils = {
  getErrorString: (error) => {
    let textError = '';
    const arr = Object.values(error.response.data).flat();
    for (let i = 0; i < arr.length; i += 1) {
      textError += `${arr[i]}\n`;
    }
    return textError;
  },
  showErrorToast: (error) => {
    if (error.response?.status === errorsCodes.SERVER_ERROR) {
      toast.error(error.response.statusText);
    }

    if (error.response?.data) {
      toast.error(utils.getErrorString(error));
    } else {
      toast.error(error.message ? error.message : error);
    }
  },
  toArrayMapping: (data) => {
    return data.reduce((dict, item) => {
      dict[item.id] = item;
      return dict;
    }, {});
  },
  removeItemsFromArray: (arr, target) => {
    return arr.filter((item) => item !== target);
  },
  getProjectUserMap: (project) => {
    const users = [...project.participants, ...project.removed_participants, project.created_by];
    return utils.toArrayMapping(users);
  },
  inputLimiter: (event) => {
    if (event.key !== 'Backspace' && event.target.value.length >= globals.MAX_TAG_LENGTH) {
      event.preventDefault();
      message.error(errors.CHARACTER_LIMIT_REACHED);
    }
  },
  focusCellElement: (elementId) => {
    return {
      onClick: () => {
        let element = document.getElementById(elementId);
        if (element) {
          element.focus();
        }
      },
    };
  },
};
