import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';

export const fetchSummary = createAsyncThunk(
  'summary/fetchSummary',
  async (id) => {
    return await api.getProjectSummary(id);
  },
);

const INITIAL_STATE = {
  answers: {},
  answersLoading: false,
};

const summarySlice = createSlice({
  name: 'summary',
  initialState: INITIAL_STATE,
  reducers: {
    resetSummarySlice: () => INITIAL_STATE,
    updateAnswer: (state, action) => {
      const answers = state.answers
      Object.assign(answers, action.payload)
      state.answers = answers
    }
  },

  extraReducers: {
    [fetchSummary.pending]: (state) => {
      state.answersLoading = true;
    },
    [fetchSummary.rejected]: (state) => {
      state.answersLoading = false;
    },
    [fetchSummary.fulfilled]: (state, action) => {
      state.answersLoading = false;
      state.answers = action.payload;
    },
  },
});

export const {resetSummarySlice, updateAnswer} = summarySlice.actions;
export default summarySlice.reducer;