import { combineReducers } from '@reduxjs/toolkit';
import {
  authReducer,
  projectReducer,
  userReducer,
  beneficiariesReducer,
  beneficiariesOutputsSliceReducer,
  influencesReducer,
  problemCanvas,
  solutionCanvas,
  summary,
  typingSlice as typing,
} from 'src/redux/reducers';

export default combineReducers({
  auth: authReducer,
  project: projectReducer,
  user: userReducer,
  beneficiaries: beneficiariesReducer,
  influences: influencesReducer,
  beneficiariesOutputs: beneficiariesOutputsSliceReducer,
  problemCanvas,
  solutionCanvas,
  summary,
  typing,
});
