import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';

export const fetchProjectTechnologies = createAsyncThunk(
  'problemCanvas/fetchProjectTechnologies',
  async (id) => {
    return await api.getProjectTechnologies(id);
  },
);

export const fetchSolutions = createAsyncThunk('problemCanvas/fetchSolutions', async (id) => {
  return await api.getSolutions(id);
});

const INITIAL_STATE = {
  technologies: [],
  solutions: [],
  loading: false,
  error: null,
};

const problemCanvasSlice = createSlice({
  name: 'problemCanvas',
  initialState: INITIAL_STATE,
  reducers: {
    resetProblemCanvas: () => INITIAL_STATE,
    addNewTechnology: (state, action) => {
      state.technologies.push(action.payload);
    },
    deleteTechnology: (state, action) => {
      state.technologies = state.technologies.filter(({id}) => id !== action.payload.id);
    },
    deleteSolution: (state, action) => {
      const {id: deletedId, technology, sub_problem} = action.payload;

      if (state.solutions[sub_problem] && state.solutions[sub_problem][technology]) {
        const currentSolutions = state.solutions[sub_problem][technology];
        state.solutions[sub_problem][technology] = currentSolutions.filter(
          ({id}) => id !== deletedId,
        );
      }
    },

    updateSolutions: (state, action) => {
      state.solutions[action.payload.id] = {name: action.payload.name};
    },
    updateTechnology: (state, action) => {
      const {id} = action.payload;
      const index = state.technologies.findIndex((tech) => tech.id === id);

      if (index !== -1) {
        state.technologies[index] = action.payload;
      }
    },
    updateSubProblemName: (state, action) => {
      state.solutions[action.payload.id] = {
        ...state.solutions[action.payload.id],
        name: action.payload.name,
      };
    },
    deleteSubProblem: (state, action) => {
      delete state.solutions[action.payload.id];
    },
    handleProjectSolutionCreated: (state, action) => {
      const {technology, sub_problem} = action.payload;
      state.solutions[sub_problem][technology] = [
        ...(state.solutions[sub_problem][technology] || []),
        action.payload,
      ];
    },
  },
  extraReducers: {
    [fetchProjectTechnologies.pending]: (state) => {
      state.loading = true;
    },
    [fetchProjectTechnologies.fulfilled]: (state, action) => {
      state.loading = false;
      state.technologies = action.payload;
    },
    [fetchProjectTechnologies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [fetchSolutions.pending]: (state) => {
      state.loading = true;
    },
    [fetchSolutions.fulfilled]: (state, action) => {
      state.loading = false;
      state.solutions = action.payload;
    },
    [fetchSolutions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const {
  resetProblemCanvas,
  addNewTechnology,
  updateSolutions,
  updateTechnology,
  updateSubProblemName,
  handleProjectSolutionCreated,
  deleteTechnology,
  deleteSolution,
  deleteSubProblem
} = problemCanvasSlice.actions;

export default problemCanvasSlice.reducer;
