import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'src/components/opItem/_opItem.scss';
import User from '../user/User';
import { Button, Input, Modal } from 'antd';
import { EditOutlined, LikeOutlined, LikeFilled } from '@ant-design/icons';
import Typography from '../typography/typography';

function OpItem({ id, opportunity, onLikeButtonClick, onOpEdit }) {
  const userObject = JSON.parse(localStorage.getItem('user'));
  const userId = userObject?.id;
  const isCreator = opportunity?.created_by === userId;

  const [isChecked, setIsChecked] = useState(opportunity?.likers?.includes(userId));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const project = useSelector((state) => state.project.selected);

  const showModal = () => {
    setInputValue(opportunity.name);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    onOpEdit(opportunity.id, inputValue);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLikeButtonClick = () => {
    setIsChecked(!isChecked);
    onLikeButtonClick(opportunity.id, isChecked);
  };

  const selectParticipantById = (participantId) => {
    const participants = [...project.participants, project.created_by];
    return participants.find((participant) => participant.id === participantId);
  };

  const user = selectParticipantById(opportunity?.created_by);

  return (
    <div className='opItem'>
      <Button
        type='primary'
        style={{
          backgroundColor: isChecked ? '#212D6D' : '#EAEBF0',
          color: isChecked ? 'white' : '#212D6D',
          margin: '15px',
          marginLeft: '0',
        }}
        shape='circle'
        icon={isChecked ? <LikeFilled/> :<LikeOutlined />}
        onClick={handleLikeButtonClick}
      />
      <div className='left'>
        <Typography>
          {id} - {opportunity.name}
        </Typography>
        <div className='supporter-names'>
          {opportunity?.likers.length > 0 && (
            <>
              <Typography color='#808080' fontSize='12px' style={{ marginRight: 5 }}>
                Likes:
              </Typography>
              {opportunity?.likers.map((liker, index) => (
                <User key={index} user={project?.userMap[liker]} />
              ))}
            </>
          )}
        </div>
      </div>
      <div className='right'>
        {isCreator ? (
          <Button
            type='text'
            style={{ margin: '15px', marginRight: '0' }}
            shape='circle'
            icon={<EditOutlined />}
            onClick={showModal}
          />
        ) : (
          user && <User user={user} />
        )}
      </div>
      <Modal
        title='Edit Opportunity'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Save'
      >
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder='Enter something'
        />
      </Modal>
    </div>
  );
}

export default OpItem;
