import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketURLs } from './urls';
import { local } from '../common';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const project = useSelector((state) => state.project.selected);
  const [socketUrl, setSocketUrl] = useState(null);
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    share: true,
  });

  const refreshSocketConnection = useCallback(() => {
    if (readyState === ReadyState.UNINSTANTIATED) return;
    if (readyState !== WebSocket.OPEN) {
      setSocketUrl(null);
      setTimeout(() => {
        setSocketUrl(() => socketURLs.PROJECT(project?.id, local.getAccessToken()));
      }, 0);
    }
  }, [project?.id]);

  useEffect(() => {
    if (project) {
      setSocketUrl(() => socketURLs.PROJECT(project?.id, local.getAccessToken()));
    }
  }, [project]);

  useEffect(() => {
    window.addEventListener('online', refreshSocketConnection);
    window.addEventListener('offline', refreshSocketConnection);
    return () => {
      window.removeEventListener('online', refreshSocketConnection);
      window.removeEventListener('offline', refreshSocketConnection);
    };
  }, [refreshSocketConnection]);

  return (
    <WebSocketContext.Provider
      value={{
        sendJsonMessage,
        lastJsonMessage,
        readyState,
        refreshSocketConnection,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};
