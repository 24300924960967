import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, message, Modal, Select, Table } from 'antd';
import debounce from 'lodash/debounce';

import Typography from 'src/components/typography/typography';
import { NavigationFooter } from 'src/components';
import Button from 'src/components/button';

import { globals, hooks, labels as pageheaderlabels, routes, utils } from 'src/common';
import { projectActions } from 'src/redux/actions';
import { fetchPotentialSolutions, fetchProjectFactors } from 'src/redux/reducers/solutionCanvasSlice';
import { labels, primaryRankingColumns, secondaryRankingColumns } from './constants';
import { addFactorColumns, createPotentialSolutionRows } from './helpers';
import { fetchSolutions } from 'src/redux/reducers/problemCanvasSlice';
import { api } from 'src/api';
import { ReadyState } from 'react-use-websocket';
import { send_events, useWebSocketContext } from 'src/sockets';
import TextInput from 'src/components/textInput/TextInput';
import AddFactorsModal from 'src/pages/solutionCanvas/AddFactorsModal';

const SolutionCanvas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector((state) => state.project.selected);
  const { potentialSolutions, potentialSolutionsLoading, factors } = useSelector((state) => state.solutionCanvas);
  const { id } = useParams();
  const [solutionSuggestions, setSolutionSuggestions] = useState([]);
  const [allSolutionSuggestions, setAllSolutionSuggestions] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(undefined);
  const [isPotentialSolutionModalOpen, setIsPotentialSolutionModalOpen] = useState(false);
  const [isAddFactorsModalOpen, setIsAddFactorsModalOpen] = useState(false);
  const [isEditFactorsModalOpen, setIsEditFactorsModalOpen] = useState(false);
  const [editFactorName, setEditFactorName] = useState('');
  const [editFactorId, setEditFactorId] = useState(null);
  const { sendJsonMessage, readyState } = useWebSocketContext();

  const openFactorEditModal = (factorName, factorID) => {
    setIsEditFactorsModalOpen(true);
    setEditFactorName(factorName);
    setEditFactorId(factorID);
  };

  const handleFactorDeletion = (id) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.DELETE_PROJECT_FACTOR,
        data: { id },
      });
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: labels.CONFIRM_FACTOR_DELETION,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleFactorDeletion(id);
        message.success(labels.FACTOR_DELETED_SUCCESSFULLY);
      },
    });
  };

  const secondTableColumns = addFactorColumns(
    secondaryRankingColumns,
    factors,
    openFactorEditModal,
    handleDelete,
  );

  const debouncedGetSolutionSuggestions = debounce(async (search) => {
    setSolutionSuggestions(allSolutionSuggestions.filter(suggestion => suggestion.label.toLowerCase().includes(search.toLowerCase())));
  }, 300);

  const handleAddPSClick = async () => {
    const response = await api.getSolutionSuggestions(id, '');
    const suggestions = response.map((option) => ({ key: option['id'], value: option['id'], label: option['name'] }));
    setAllSolutionSuggestions(suggestions);
    setSolutionSuggestions(suggestions);
    setIsPotentialSolutionModalOpen(true);
  };

  const handlePotentialSolutionCreation = (solution) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.CREATE_POTENTIAL_SOLUTION,
        data: { solution },
      });
    }
    setIsPotentialSolutionModalOpen(false);
  };

  const handleFactorCreation = (name) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.CREATE_PROJECT_FACTOR,
        data: { factor: { name } },
      });
    }
  };

  const handleScoreUpdate = (type, id, value) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.UPDATE_POTENTIAL_SOLUTION,
        data: { id, [type]: value },
      });
    }
  };

  const handlePsFactorUpdate = (ps_id, factor_id, value) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.SAVE_PS_FACTOR,
        data: { potential_solution: ps_id, project_factor: factor_id, detail: value },
      });
    }
  };

  const handleTagAdd = (tagType, ps_id, value) => {
    if (readyState === ReadyState.OPEN) {
      if (tagType === 'entity') {
        sendJsonMessage({
          type: send_events.CREATE_PS_ENTITY,
          data: { potential_solution: ps_id, entity: { name: value } },
        });
      } else if (tagType === 'technology') {
        sendJsonMessage({
          type: send_events.CREATE_PS_TECHNOLOGY,
          data: { potential_solution: ps_id, technology: { name: value } },
        });
      }
    }
  };

  const handleTagRemove = (tagType, id) => {
    if (readyState === ReadyState.OPEN) {
      if (tagType === 'entity') {
        sendJsonMessage({
          type: send_events.DELETE_PS_ENTITY,
          data: { id },
        });
      } else if (tagType === 'technology') {
        sendJsonMessage({
          type: send_events.DELETE_PS_TECHNOLOGY,
          data: { id },
        });
      }
    }
  };

  const handleFactorEdit = () => {
    if (editFactorName) {
      if (readyState === ReadyState.OPEN) {
        sendJsonMessage({
          type: send_events.UPDATE_PROJECT_FACTOR,
          data: { id: editFactorId, factor: { name: editFactorName } },
        });
      }
      setIsEditFactorsModalOpen(false);
      setEditFactorName('');
      setEditFactorId(null);
    }
    else{
      message.error(labels.FACTOR_CANNOT_BE_EMPTY)
    }
  };

  const handleTagSearch = async (tagType, ps_id, search) => {
    let response;

    if (tagType === 'entity') {
      response = await api.getSuggestedEntities(search, null, null, ps_id);
    } else if (tagType === 'technology') {
      response = await api.getSuggestedTechnologies(search, null, ps_id);
    }
    return response['results'].map((option) => ({ key: option['id'], value: option['name'] }));
  };

  const rows = createPotentialSolutionRows(
    potentialSolutions,
    factors,
    handleScoreUpdate,
    handlePsFactorUpdate,
    handleTagAdd,
    handleTagRemove,
    handleTagSearch,
  );

  const onFactorsModalClose = () => {
    setIsAddFactorsModalOpen(false);
  };

  useEffect(() => {
    dispatch(projectActions.getProject(id));
    dispatch(fetchProjectFactors(id));
    dispatch(fetchPotentialSolutions(id));
    dispatch(fetchSolutions(id));
  }, [id]);


  hooks.usePageTitle(pageheaderlabels.SOLUTION_CANVAS);
  hooks.useScrollToTop();

  return (
    <>
      <div className='solutionCanvasContainerStyle'>
        <Typography fontSize='24px'>
          Big Opportunity:{' '}
          <Typography fontSize='30px' fontWeight={500} blue>
            {project?.big_opportunity?.name || ''}
          </Typography>
        </Typography>
        <Divider />
        <Typography tag='p' fontSize='30px' fontWeight={500} blue>
          SOLUTION CANVAS
        </Typography>
        <div style={{ maxWidth: 873 }}>
          <Typography fontSize='14px' fontStyle='italic' lineHeight='21px'>
            The main goal of solution canvas is to facilitate an objective conversation about the
            positives and negatives of each potential solution. The canvas reduces the influence of
            ‘pet-projects’ promoted by top leaders, allows a small advantage to quiet thoughtful
            innovators over loudmouths and addresses your own bias. It also challenges the toxic
            positivity of ‘anything is possible’ you often see in the early stages of startups, or
            when an organization is driving a project more by jealousy by mimicking the latest
            product of their competitors rather than focusing on the organization`s own strength.
          </Typography>
        </div>
        <Typography fontSize='14px' fontStyle='italic' lineHeight='21px'>
          <ol>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Paste your top 10 Potential Solutions from Problem Canvas.
              </Typography>
            </li>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Rank the potential solutions by their cost, time required, and level of impact.
              </Typography>
            </li>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Write down as many groups as you can think of who would be impacted within the
                “billions”.
              </Typography>
            </li>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                Write down what potential technologies can be leveraged for these potential
                solutions.
              </Typography>
            </li>
          </ol>
        </Typography>

        <Table
          loading={potentialSolutionsLoading}
          columns={primaryRankingColumns.map((column) => ({
            ...column,
            onCell: (record, rowIndex) => {
              return utils.focusCellElement(`${rowIndex}-${column?.dataIndex}-Solution-Canvas`);
            },
          }))}
          dataSource={rows}
          pagination={false}
          className='solution-table'
          bordered
          components={{
            // eslint-disable-next-line no-unused-vars
            body: { cell: ({ onMouseEnter, onMouseLeave, ...restProps }) => <td {...restProps} /> },
          }}
        />

        <Typography fontSize='14px' fontStyle='italic' lineHeight='21px'>
          <ol start='5'>
            <li>
              <Typography fontSize='14px' fontStyle='italic'>
                If time allows, also fill out other relevant factors that may impact the big
                opportunity and potential solution - feel free to add relevant ones to your case
                factors.
              </Typography>
            </li>
          </ol>
        </Typography>

        <Table
          loading={potentialSolutionsLoading}
          columns={secondTableColumns.map((column) => ({
            ...column,
            onCell: (record, rowIndex) => {
              return utils.focusCellElement(`${rowIndex}-${column?.dataIndex}-Solution-Canvas`);
            },
          }))}
          dataSource={rows}
          pagination={false}
          className='solution-table'
          bordered
          components={{
            // eslint-disable-next-line no-unused-vars
            body: { cell: ({ onMouseEnter, onMouseLeave, ...restProps }) => <td {...restProps} /> },
          }}
          scroll={{ x: true }}
        />

        <div>
          <Modal
            title='Add Potential Solution'
            open={isPotentialSolutionModalOpen}
            okText={'Add'}
            onOk={() => {
              selectedSolution ? handlePotentialSolutionCreation(selectedSolution) : message.error(labels.POTENTIAL_SOLUTION_NOT_SELECTED);
              setSelectedSolution(undefined);
            }}
            onCancel={() => {
              setIsPotentialSolutionModalOpen(false);
              setSelectedSolution(undefined);
            }}
          >
            <Select
              showSearch
              placeholder='Select a solution'
              optionFilterProp='children'
              options={solutionSuggestions}
              onChange={setSelectedSolution}
              value={selectedSolution}
              onSearch={debouncedGetSolutionSuggestions}
              filterOption={false}
            />
          </Modal>

          <AddFactorsModal isModalOpen={isAddFactorsModalOpen} onClose={onFactorsModalClose}
                           handleFactorCreation={handleFactorCreation} />

          <Modal
            title='Edit Factor'
            open={isEditFactorsModalOpen}
            okText={pageheaderlabels.UPDATE}
            onOk={() => handleFactorEdit()}
            onCancel={() => {
              setIsEditFactorsModalOpen(false);
              setEditFactorName('');
              setEditFactorId(null);
            }}
          >
            <TextInput initialValue={editFactorName} onUpdate={setEditFactorName}
                       style={{ border: '1px solid #ccc' }} maxLength={globals.MAX_TAG_LENGTH}
                       onKeyDown={(e) => {
                         utils.inputLimiter(e);
                       }} />
          </Modal>

          <div className='solutionCanvas__actionButtonContainer'>
            <Button onClick={handleAddPSClick}>
              Add Potential Solution
            </Button>
            <Button onClick={() => setIsAddFactorsModalOpen(true)}>Add Factor</Button>
          </div>
        </div>
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}/problem-canvas`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/findings-plot`)}
      />
    </>
  );
};

export default SolutionCanvas;
