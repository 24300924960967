import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from 'src/redux/actions';
import 'src/pages/opportunity/_opportunity.scss';
import { hooks, labels, routes } from 'src/common';
import { useNavigate, useParams } from 'react-router-dom';
import { clauses } from 'src/common/constants';
import { send_events, useWebSocketContext } from 'src/sockets';
import { ReadyState } from 'react-use-websocket';
import Typography from 'src/components/typography/typography';
import Button from 'src/components/button';
import { NavigationFooter, OpItem } from 'src/components';
import { Empty } from 'antd';

function Opportunity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const opRef = useRef();
  const { selected: project, opportunities } = useSelector((state) => state.project);
  const { id } = useParams();
  const { sendJsonMessage, readyState } = useWebSocketContext();
  const [opportunityName, setOpportunityName] = useState('');

  const scrollOpportunitiesToBottom = () => {
    opRef.current?.scrollTo(0, opRef.current?.scrollHeight);
  };

  const onOpLikeButtonClick = (id, state) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.LIKE_OPPORTUNITY,
        data: { id, is_liked: !state },
      });
    }
  };

  const editOp = (id, name) => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.UPDATE_OPPORTUNITY,
        data: { id, name },
      });
    }
  };

  const handleInputChange = (e) => {
    setOpportunityName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.CREATE_OPPORTUNITY,
        data: { name: opportunityName },
      });
      setOpportunityName('');
    }
  };

  useEffect(() => {
    if (project && opportunities.length === 0) {
      dispatch(projectActions.getOpportunities(project.id));
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (!project) {
      dispatch(projectActions.getProject(id));
    }
  }, [dispatch, id, project]);

  useEffect(() => {
    scrollOpportunitiesToBottom();
  }, [opportunities.length]);

  hooks.usePageTitle(labels.OPPORTUNITIES);
  hooks.useScrollToTop();

  return (
    <>
      <div className='opportunity'>
        <div className='opportunityContainer'>
          <div className='topContent'>
            <Typography bold blue fontSize='24px' tag='h1' className='title'>
              {labels.OPPORTUNITIES}
            </Typography>
            {opportunities.length ? (
              <div ref={opRef} className='items'>
                {opportunities.map((op, index) => {
                  return (
                    <OpItem
                      key={index}
                      id={index + 1}
                      opportunity={op}
                      onLikeButtonClick={onOpLikeButtonClick}
                      onOpEdit={editOp}
                    />
                  );
                })}

              </div>) : (
              <div className='emptyContainerStyles'>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No opportunties added yet! Feel free to add new opportunities."
                />
              </div>
            )}
          </div>
          <form className='bottomContent' onSubmit={handleSubmit}>
            <div className='inputRow'>
              <label htmlFor='name'>
                <Typography fontSize='16px'>{clauses.OPPORTUNITY}</Typography>
              </label>
              <input
                id='name'
                name='name'
                value={opportunityName}
                onChange={handleInputChange}
                placeholder='Enter Opportunity'
              />
            </div>
            <div className='buttonContainer'>
              <Button className='submitBtn' htmlType='submit' disabled={!opportunityName.trim()}>
                {labels.ADD_OPPORTUNITY}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <NavigationFooter
        onBack={() => navigate(`${routes.PROJECT}/${project?.id}`)}
        onNext={() => navigate(`${routes.PROJECT}/${project?.id}/selected-opportunity`)}
      />
    </>
  );
}

export default Opportunity;
