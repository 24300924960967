import { Button, Input, message, Modal, Select } from 'antd';
import { getEntitySelectOptions } from './helpers';
import React, { useEffect, useState } from 'react';
import { errors, labels } from 'src/common';
import { ReadyState } from 'react-use-websocket';
import { send_events, useWebSocketContext } from 'src/sockets';

const OutputModal = ({ isModalOpen, onClose, tags, outputs, outputToUpdate }) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { sendJsonMessage, readyState } = useWebSocketContext();

  const onOutputSaved = (project_entity, justification) => {
    if (readyState === ReadyState.OPEN) {
      if (outputToUpdate) {
        sendJsonMessage({
          type: send_events.UPDATE_PEOPLE_ORG_OUTPUT,
          data: { id: outputToUpdate?.key, project_entity, justification },
        });
      } else {
        sendJsonMessage({
          type: send_events.CREATE_PEOPLE_ORG_OUTPUT,
          data: { project_entity, justification },
        });
      }
    }
  };

  const onSelectedEntityChange = (value) => {
    setSelectedEntity(value);
  };

  const filterOption = (input, option) =>
    (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase());

  const handleOk = () => {
    if (!selectedEntity) {
      message.error(errors.ENTITY_NOT_SELECTED);
      return;
    }

    if (!inputValue) {
      message.error(errors.ENTER_JUSTIFICATION);
      return;
    }

    if (outputs.length >= 10) {
      message.error(errors.OUTPUT_LIMIT_REACHED);
      return;
    }

    onOutputSaved(selectedEntity, inputValue);

    setInputValue('');
    setSelectedEntity(null);
    onClose();
  };

  const handleCancel = () => {
    setInputValue('');
    setSelectedEntity(null);
    onClose();
  };

  const handleDelete = () => {
    setInputValue('');
    setSelectedEntity(null);

    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: send_events.DELETE_PEOPLE_ORG_OUTPUT,
        data: { id: outputToUpdate?.key },
      });
    }

    onClose();
  };

  useEffect(() => {
    setSelectedEntity(outputToUpdate?.entityId);
    setInputValue(outputToUpdate?.Justification);
  }, [outputToUpdate]);

  return (
    <>
      <Modal
        title='Add Entity'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Save'
        footer={[
          outputToUpdate && (
            <Button key='delete' danger onClick={handleDelete} style={{ float: 'left', marginRight: '8px' }}>
              Delete
            </Button>),
          <Button key='cancel' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='ok' type='primary' onClick={handleOk}>
            {outputToUpdate ? labels.UPDATE : 'Save'}
          </Button>,
        ]}
      >
        <Select
          showSearch
          value={selectedEntity}
          placeholder='Select an Entity'
          optionFilterProp='children'
          onChange={onSelectedEntityChange}
          filterOption={filterOption}
          options={getEntitySelectOptions(tags, outputs, outputToUpdate)}
        />
        <Input
          placeholder='How it is related, how will you be different'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default OutputModal;
