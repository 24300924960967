import { createSlice } from '@reduxjs/toolkit';
import { projectActions } from 'src/redux/actions';
import { local, utils } from "src/common";

const INITIAL_STATE = {
  owned: [],
  ownedLoading: false,
  joined: [],
  joinedLoading: false,
  selected: null,
  selectedOpportunity: [],
  selectedLoading: false,
  opportunities: [],
  opportunitiesLoading: false,
  selectedOpportunities: false,
  criteriaLoading: false,
  criteria: [],
  projectEntities: {},
  projectEntitiesLoading: false,
  peopleAndOrgOutputs: [],
  peopleAndOrgOutputsLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetProjectSlice: () => INITIAL_STATE,
    updateProjectSignal: (state, {payload}) => {
      const user = local.getUser();
      if (user.id !== payload.created_by.id) {
        state.selected = payload;
        state.selected.userMap = utils.getProjectUserMap(payload);
      }
    },
    addOpportunity: (state, {payload}) => {
      state.opportunities = [...state.opportunities, payload];
    },
    updateOpportunity: (state, {payload}) => {
      state.opportunities = state.opportunities.map((opportunity) => {
        if (opportunity.id === payload.id) {
          return payload;
        }
        return opportunity;
      });
    },
    updateOpportunityCriterion: (state, {payload}) => {
      let selectedOpportunity = [...state.selectedOpportunity];
      const index = selectedOpportunity.findIndex((opportunity) => opportunity.id === payload.id);
      if (index !== -1) {
        selectedOpportunity[index] = payload;
        state.selectedOpportunity = selectedOpportunity;
      }
    },

    updateBigOpportunity: (state, {payload}) => {
      state.selected.big_opportunity = payload;
    },

    addProjectEntity: (state, {payload}) => {
      const {id, created_by, pe_type, entity_name} = payload;

      const projectEntities = {...state.projectEntities};

      if (!(created_by in projectEntities)) {
        projectEntities[created_by] = {};
      }

      if (!(pe_type in projectEntities[created_by])) {
        projectEntities[created_by][pe_type] = [];
      }

      projectEntities[created_by][pe_type].push({id, entity_name});
      state.projectEntities = projectEntities;
    },

    deleteProjectEntity: (state, {payload}) => {
      const {id, created_by, pe_type} = payload;

      const projectEntities = {...state.projectEntities};
      projectEntities[created_by][pe_type] = projectEntities[created_by][pe_type].filter(entity => entity.id !== id);

      state.projectEntities = projectEntities;
    },

    addOutput: (state, {payload}) => {
      state.peopleAndOrgOutputs = [...state.peopleAndOrgOutputs, payload];
    },

    updateOutput: (state, {payload}) => {
      const peopleAndOrgOutputs = state.peopleAndOrgOutputs
      const index = peopleAndOrgOutputs.findIndex((output) => output.id === payload.id);
      if (index !== -1) {
        peopleAndOrgOutputs[index] = payload;
        state.peopleAndOrgOutputs = peopleAndOrgOutputs
      }
    },

    deleteOutput: (state, {payload}) => {
      const peopleAndOrgOutputs = state.peopleAndOrgOutputs
      state.peopleAndOrgOutputs = peopleAndOrgOutputs.filter(output => output.id !== payload.id)
    },
  },

  extraReducers: (builder) => {
    builder.addCase(projectActions.getOwnedProjects.pending, (state, {payload}) => {
      state.ownedLoading = true;
    });
    builder.addCase(projectActions.getOwnedProjects.rejected, (state, {payload}) => {
      state.ownedLoading = false;
    });
    builder.addCase(projectActions.getOwnedProjects.fulfilled, (state, {payload}) => {
      state.ownedLoading = false;
      state.owned = payload;
    });
    builder.addCase(projectActions.getJoinedProjects.pending, (state, {payload}) => {
      state.joinedLoading = true;
    });
    builder.addCase(projectActions.getJoinedProjects.rejected, (state, {payload}) => {
      state.joinedLoading = false;
    });
    builder.addCase(projectActions.getJoinedProjects.fulfilled, (state, {payload}) => {
      state.joinedLoading = false;
      state.joined = payload;
    });
    builder.addCase(projectActions.getProject.pending, (state, {payload}) => {
      state.selectedLoading = true;
    });
    builder.addCase(projectActions.getProject.rejected, (state, {payload}) => {
      state.selectedLoading = false;
    });
    builder.addCase(projectActions.getProject.fulfilled, (state, {payload}) => {
      state.selectedLoading = false;
      state.selected = payload;
      state.selected.userMap = utils.getProjectUserMap(payload);
    });
    builder.addCase(projectActions.createProject.pending, (state, {payload}) => {
      state.selectedLoading = true;
    });
    builder.addCase(projectActions.createProject.rejected, (state, {payload}) => {
      state.selectedLoading = false;
    });
    builder.addCase(projectActions.createProject.fulfilled, (state, {payload}) => {
      state.selectedLoading = false;
      state.selected = payload;
      state.selected.userMap = utils.getProjectUserMap(payload);
    });
    builder.addCase(projectActions.updateProject.pending, (state, {payload}) => {
      state.selectedLoading = true;
    });
    builder.addCase(projectActions.updateProject.rejected, (state, {payload}) => {
      state.selectedLoading = false;
    });
    builder.addCase(projectActions.updateProject.fulfilled, (state, {payload}) => {
      state.selectedLoading = false;
      state.selected = payload;
      state.selected.userMap = utils.getProjectUserMap(payload);
    });
    builder.addCase(projectActions.getOpportunities.pending, (state, {payload}) => {
      state.opportunitiesLoading = true;
    });
    builder.addCase(projectActions.getOpportunities.rejected, (state, {payload}) => {
      state.opportunitiesLoading = false;
    });
    builder.addCase(projectActions.getOpportunities.fulfilled, (state, {payload}) => {
      state.opportunitiesLoading = false;
      state.opportunities = payload;
    });
    builder.addCase(projectActions.getSelectedOpportunities.pending, (state, {payload}) => {
      state.selectedOpportunities = true;
    });
    builder.addCase(projectActions.getSelectedOpportunities.rejected, (state, {payload}) => {
      state.selectedOpportunities = false;
    });
    builder.addCase(projectActions.getSelectedOpportunities.fulfilled, (state, {payload}) => {
      state.selectedOpportunities = false;
      state.selectedOpportunity = payload;
    });
    builder.addCase(projectActions.getCriteria.pending, (state, {payload}) => {
      state.criteriaLoading = true;
    });
    builder.addCase(projectActions.getCriteria.rejected, (state, {payload}) => {
      state.criteriaLoading = false;
    });
    builder.addCase(projectActions.getCriteria.fulfilled, (state, {payload}) => {
      state.criteriaLoading = false;
      state.criteria = payload;
    });
    builder.addCase(projectActions.getProjectEntities.pending, (state, {payload}) => {
      state.projectEntitiesLoading = true;
    });
    builder.addCase(projectActions.getProjectEntities.rejected, (state, {payload}) => {
      state.projectEntitiesLoading = false;
    });
    builder.addCase(projectActions.getProjectEntities.fulfilled, (state, {payload}) => {
      state.projectEntitiesLoading = false;
      state.projectEntities = payload;
    });
    builder.addCase(projectActions.getPeopleOrgOutputs.pending, (state, {payload}) => {
      state.peopleAndOrgOutputsLoading = true;
    });
    builder.addCase(projectActions.getPeopleOrgOutputs.rejected, (state, {payload}) => {
      state.peopleAndOrgOutputsLoading = false;
    });
    builder.addCase(projectActions.getPeopleOrgOutputs.fulfilled, (state, {payload}) => {
      state.peopleAndOrgOutputsLoading = false;
      state.peopleAndOrgOutputs = payload;
    });
  },
});

export const {actions, reducer} = authSlice;
export default reducer;
