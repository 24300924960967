import { createSlice } from '@reduxjs/toolkit';
import { authActions } from 'src/redux/actions';
import { local } from 'src/common';

const INITIAL_STATE = {
  loading: false,
  tokens: {
    access: '',
    refresh: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetAuthSlice: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.login.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(authActions.login.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(authActions.login.fulfilled, (state, { payload }) => {
      const { access, refresh, user } = payload;
      state.loading = false;
      state.tokens = { access, refresh };
      local.storeUser(user);
    });
  },
});

const { actions, reducer } = authSlice;
export default reducer;
