export const tokenKeys = {
  ACCESS: 'accessToken',
  REFRESH: 'refreshToken',
};

export const local = {
  storeTokens: (tokens) => {
    localStorage.setItem(tokenKeys.ACCESS, tokens.access);
    localStorage.setItem(tokenKeys.REFRESH, tokens.refresh);
  },
  storeAccessToken: (access_token) => {
    localStorage.setItem(tokenKeys.ACCESS, access_token);
  },
  getAccessToken: () => {
    return localStorage.getItem(tokenKeys.ACCESS);
  },
  getRefreshToken: () => {
    return localStorage.getItem(tokenKeys.REFRESH);
  },
  clearLocalStorge: () => {
    localStorage.clear();
  },
  storeUser: (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  },
  getUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },
};
